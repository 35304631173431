<form *ngIf="addUserForm" [formGroup]="addUserForm">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div class="form-group row">
          <mat-form-field>
            <mat-label>Employé</mat-label>
            <mat-select formControlName="employee" [errorStateMatcher]="matcher" required (closed)="reseatEmployeeSearchField()">
              <input class="form-control" type="text" [formControl]="employeeFilter" placeholder="Rechercher">
              <mat-option *ngFor="let employee of filteredEmployees | async" [value]="employee.id" (click)="selectEmployee(employee)">{{employee.firstName}} {{employee.lastName}}</mat-option>
            </mat-select>
            <mat-error *ngIf="addUserForm.get('employee').hasError('required')">Le choix de l'employé est
              <strong>requis</strong> !
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group row">
          <mat-form-field>
            <input matInput placeholder="Trigram" formControlName="trigram"
                   [errorStateMatcher]="matcher" appDirUppercase appDirNormalize readonly required>
            <mat-error *ngIf="addUserForm.get('trigram').hasError('required')">Le trigram est
              <strong>requis</strong> !
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group row">
          <mat-form-field>
            <mat-label>Role</mat-label>
            <mat-select formControlName="role" [errorStateMatcher]="matcher" required>
              <mat-option *ngFor="let role of userRoles" [value]="role">{{role.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="addUserForm.get('role').hasError('required')">Le role est
              <strong>requis</strong> !
            </mat-error>
          </mat-form-field>
        </div>

      </div>
      <div class="col-md-6 col sm-6">
        <div class="form-group row">
          <mat-form-field>
            <input matInput placeholder="Nom d'utilisateur" formControlName="username"
                   [errorStateMatcher]="matcher" appDirNormalize required>
            <mat-error *ngIf="addUserForm.get('username').hasError('required')">Le nom d'utilisateur est
              <strong>requis</strong> !
            </mat-error>
          </mat-form-field>
        </div>
        <app-reset-password [form]="addUserForm"></app-reset-password>
      </div>
    </div>
  </div>
</form>

<div *ngIf="addUserForm" class="container-fluid">
  <div class="row">
    <div class="col-sm text-right">
      <button mat-raised-button class="avoidButton"
              (click)="cancel()">
        <mat-icon>clear</mat-icon>
        <strong> ANNULER</strong>
      </button>
      <button mat-raised-button class="invalidButton"
              [hidden]="addUserForm.valid" disabled>
        <mat-icon>error_outline</mat-icon>
        <strong> UTILISATEUR INVALIDE</strong>
      </button>
      <button type="button" mat-raised-button class="addButton"
              [hidden]="!addUserForm.valid"
              (click)="onSubmitForm()">
        <mat-icon>done</mat-icon>
        <strong> AJOUTER UTILISATEUR</strong>
      </button>
    </div>
  </div>
</div>
