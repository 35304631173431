import { Injectable } from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {Router} from '@angular/router';
import {AppService} from '../../../../shared/app.service';
import {Client} from '../../../clients/shared/client.model';
import {Observable, Subject} from 'rxjs';
import {Reporting} from './reporting.model';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  private historyUrl = environment.baseUrlOauth + '/reporting/';
  private reportingsSubject: Subject<Reporting[]>;
  resourcesLoaded: boolean;


  constructor(private router: Router,
              private appService: AppService) { }


 // Create the report by saving it in the database
  createReport(report: Reporting) {
      this.appService.postResource(this.historyUrl + 'add', report).subscribe(response => {
        console.log('create report');
      },
        error => {
        console.log('error create report');
          });
  }

  // getting all saved report in the database
  getHistories(): Observable<Reporting[]> {
    const clients = new Subject<Reporting[]>();
    return this.appService.getResource(this.historyUrl + 'all');
  }

  // update a report
  updateReport(report: Reporting) {
      this.appService.putResource(this.historyUrl, report).subscribe(
        response => {
        }, error1 => {
          console.log('update history error');
        });
  }

  // delete a report

  deleteReportById(id: number) {
  this.appService.deleteResource(this.historyUrl + id).subscribe(
      response => {
      },
      error => {
        console.log('DELETE history error : ', error);
      }
    );
  }
}
