<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#FFFFFF" type = "pacman">
  <p style="color: white" > <strong> Connexion en cours... </strong></p>
</ngx-spinner>

<body>
<div class="stars">
  <div class="custom-navbar">
    <div class="brand-logo">
      <img   src="/assets/img/logo_blanc.png" width="6%">
    </div>
  </div>

    <div class="container">
      <div class="row">
        <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
          <div class="card card-signin my-5">
            <div class="card-body">
              <h5 class="card-title text-center">Authentification</h5>
              <form (ngSubmit)="onSubmitForm()" [formGroup]="loginForm" class="form-signin" name="loginForm">
                <div class="form-group">
                  <label for="username">Utilisateur</label>
                  <input type="text" formControlName="username" id="username" class="form-control" autofocus>

                </div>


                <div class="form-group">
                  <label for="inputPassword">Mot de passe</label>
                  <input type="password" id="inputPassword"
                         [type]="showPassword ? 'text' : 'password'" formControlName="password" class="form-control">
                  <a mat-button class="labelLink" (click)="changeVisibility()">
                    <mat-icon>{{iconName}}</mat-icon> Afficher mon mot de passe
                  </a>
                </div>

                <div class="custom-control custom-checkbox mb-3">
                  <input type="checkbox" class="custom-control-input" id="customCheck1">
                  <label class="custom-control-label" for="customCheck1">Se souvenir de mon mot de passe !</label>
                </div>
                
                <a mat-button class="labelLink" [routerLink]="['/forgot-password']">Mot de passe oublié ?</a>
                
                <button class="btn btn-lg btn-primary btn-block text-uppercase"  type="submit">Se connecter</button>
                <!--              <hr class="my-4">-->
                <!--              <button class="btn btn-lg btn-google btn-block text-uppercase" type="submit"><i class="fab fa-google mr-2"></i> Se connecter avec Google</button>-->
              </form>
            </div>
          </div>
        </div>
      </div>

  </div>
  <div class="objects">
    <div class="earth-moon">
      <img class="object_earth" src="/assets/img/404/planete.svg" width="5%">
      <img class="object_moon" src="/assets/img/404/moon.svg" width="4%">
    </div>

  </div>
  <div class="glowing_stars">
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
  </div>

</div>

</body>
