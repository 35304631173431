  <mat-progress-bar mode="indeterminate" *ngIf="!loaded" class="mat-progress-bar"></mat-progress-bar>
  <mat-progress-bar mode="determinate" *ngIf="loaded" class="mat-progress-bar"></mat-progress-bar>

  <nav mat-tab-nav-bar>
    <a mat-tab-link *ngFor="let tab of navigationService.getNavItems()"
       [routerLink]="[tab.routerLink]"
       [disabled]="tab.disabled"
       [active]="tab.routerLink === router.url"><mat-icon [ngClass]="tab.class">{{tab.icon}}</mat-icon><strong>{{tab.label}}</strong></a>
  </nav>

