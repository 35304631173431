export class WorkingDay {
  constructor(
    public id?: number,
    public amount?: number,
    public idLot?: number,
    public month?: number,
    public nbDays?: number,
    public ordre?: number
  ) {}

}
