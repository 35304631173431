import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {Contact} from '../modules/contacts/shared/contact.model';
import {Employee} from '../modules/employees/shared/employee.model';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  authorities: any;
  userRole: string;
  userId: number;

  firstName: string;
  lastName: string;
  firstNameBM: string;
  lastNameBM: string;
  historyAction: string;

  // tslint:disable-next-line:max-line-length
  public socialNumberMask = [/[1278]/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/];


  constructor(
    private httpClient: HttpClient,
    private cookie: CookieService,
    private router: Router
  ) {}



  logout() {
    this.cookie.deleteAll();
    this.router.navigate(['/auth']);
  }

  // permet de get les ressources sur le micro service souhaité
  getResource(resourceUrl): Observable<any> {
    const headers = new HttpHeaders({
      'Content-type': 'application/json; charset=utf-8',
      Authorization: 'Bearer ' + this.cookie.get('access_token')
    });
    const options = {
      headers
    };
    return this.httpClient.get(resourceUrl, options);
  }

  getResourcePdf(resourceUrl): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-type': 'application/json; charset=utf-8',
      Authorization: 'Bearer ' + this.cookie.get('access_token')
    });
    return this.httpClient.get(resourceUrl, { headers, responseType: 'blob' });
  }

  postResource(resourceUrl, object): Observable<any> {
    const headers = new HttpHeaders({
      'Content-type': 'application/json; charset=utf-8',
      Authorization: 'Bearer ' + this.cookie.get('access_token')
    });
    return this.httpClient.post(resourceUrl, object, {
      headers,
      observe: 'response'
    });
  }

  uploadResource(resourceUrl, file, fileName): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.cookie.get('access_token')
    });
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);
    return this.httpClient.post(resourceUrl, formData, {
      headers,
      observe: 'response'
    });
  }

  putResource(resourceUrl, object): Observable<any> {
    const headers = new HttpHeaders({
      'Content-type': 'application/json; charset=utf-8',
      Authorization: 'Bearer ' + this.cookie.get('access_token')
    });
    return this.httpClient.put(resourceUrl, object, {
      headers,
      observe: 'response'
    });
  }

  deleteResource(resourceUrl): Observable<any> {
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      Authorization: 'Bearer ' + this.cookie.get('access_token')
    });
    return this.httpClient.delete(resourceUrl, {
      headers,
      observe: 'response'
    });
  }

  public filterResearch(allValues: string[], value: string): string[] {
    const filterValue = value.toLowerCase();
    return  allValues.filter(option => option.toLowerCase().includes(filterValue)).sort();
  }

  public filterResearchWithoutSort(allValues: string[], value: string): string[] {
    const filterValue = value.toLowerCase();
    return allValues
      .filter(option => option.toLowerCase().includes(filterValue));

  }

  public contactsFilter(allValues: Contact[], value: string): Contact[] {
    const filterValue = value.toLowerCase();
    return allValues
      .filter(option => option.firstName.toLowerCase().includes(filterValue) ||
        option.lastName.toLowerCase().includes(filterValue))
      .sort();
  }

  public employeesFilter(allValues: Employee[], value: string): Employee[] {
    const filterValue = value.toLowerCase();
    return allValues
      .filter(option => option.firstName.toLowerCase().includes(filterValue) ||
        option.lastName.toLowerCase().includes(filterValue))
      .sort();
  }


  public numberOnlyOnFields(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public allowDotOnNumericFields(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      return false;
    }
    return true;

  }
}
