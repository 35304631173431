/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@angular/material/dialog";
import * as i9 from "./info-dialog.component";
var styles_InfoDialogComponent = [i0.styles];
var RenderType_InfoDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoDialogComponent, data: {} });
export { RenderType_InfoDialogComponent as RenderType_InfoDialogComponent };
export function View_InfoDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "content-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["id", "close-icon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "content-span toast-bottom-full-width"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "button", [["id", "info-button"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).dialogRef.close(i1.ɵnov(_v, 8).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(8, 606208, null, 0, i8.MatDialogClose, [[2, i8.MatDialogRef], i1.ElementRef, i8.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(10, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["done"])), (_l()(), i1.ɵted(-1, 0, [" Ok"]))], function (_ck, _v) { _ck(_v, 3, 0); var currVal_7 = true; _ck(_v, 8, 0, currVal_7); _ck(_v, 10, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.data.message; _ck(_v, 5, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 7).disabled || null); var currVal_4 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); var currVal_5 = (i1.ɵnov(_v, 8).ariaLabel || null); var currVal_6 = i1.ɵnov(_v, 8).type; _ck(_v, 6, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 10).inline; var currVal_9 = (((i1.ɵnov(_v, 10).color !== "primary") && (i1.ɵnov(_v, 10).color !== "accent")) && (i1.ɵnov(_v, 10).color !== "warn")); _ck(_v, 9, 0, currVal_8, currVal_9); }); }
export function View_InfoDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-info-dialog", [], null, null, null, View_InfoDialogComponent_0, RenderType_InfoDialogComponent)), i1.ɵdid(1, 114688, null, 0, i9.InfoDialogComponent, [i8.MatDialogRef, i8.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InfoDialogComponentNgFactory = i1.ɵccf("app-info-dialog", i9.InfoDialogComponent, View_InfoDialogComponent_Host_0, {}, {}, []);
export { InfoDialogComponentNgFactory as InfoDialogComponentNgFactory };
