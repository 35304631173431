import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AppService } from '../../../shared/app.service';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Reporting } from '../../auth/shared/reporting/reporting.model';
import { AuthService } from '../../auth/shared/auth.service';
import { ReportingService } from '../../auth/shared/reporting/reporting.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../../shared/app.service";
import * as i3 from "ngx-toastr";
import * as i4 from "../../auth/shared/auth.service";
import * as i5 from "../../auth/shared/reporting/reporting.service";
var EmployeeService = /** @class */ (function () {
    function EmployeeService(router, appService, toastr, authService, reportingService) {
        this.router = router;
        this.appService = appService;
        this.toastr = toastr;
        this.authService = authService;
        this.reportingService = reportingService;
        this.employeeUrl = environment.baseUrlEmployees + '/employees/';
        this.resourcesLoaded = false;
        this.employeesSubject = new Subject();
        this.selectedEmployeeSubject = new Subject();
        this.employeesObservable = this.employeesSubject.asObservable();
        this.selectedEmployee = this.selectedEmployeeSubject.asObservable();
    }
    EmployeeService.prototype.getIdFromLocation = function (location) {
        var str = location.toString();
        str = str.split('/');
        this.idCreatedObject = str[(str.length) - 1];
    };
    EmployeeService.prototype.createEmployee = function (employee) {
        var _this = this;
        this.appService.postResource(this.employeeUrl + 'create', employee)
            .subscribe(function (response) {
            _this.getIdFromLocation(response.headers.getAll('location'));
            var report = new Reporting(new Date(), 'EMBAUCHE', _this.authService.user.trigram, _this.authService.user.idEmployee, 'Employe', _this.idCreatedObject);
            _this.reportingService.createReport(report);
            console.log('POST request successful', response);
            _this.getEmployeeById(_this.idCreatedObject).subscribe();
            _this.toastr.success('Candidat embauché avec succès ! ', '', { timeOut: 2000 });
        }, function (error) {
            var errorMessage = 'Erreur lors de la création du salarié ! ';
            var msg = error.error.message;
            if (error.status === 400) {
                msg = msg.split('=').pop().split(',')[0];
                errorMessage += '(' + msg + ')';
            }
            if (error.status === 401) {
                msg = 'utilisateur non authentifié';
                errorMessage += '(' + msg + ')';
            }
            if (error.status === 500 || error.status === 504 || error.status === 503) {
                if (error.error.message.toString() === 'duplicated social Number') {
                    errorMessage = 'Le numéro de sécurité sociale doit etre unique.';
                }
                else {
                    msg = 'Une erreur côté serveur est survenue.Veuillez contacter la team-IT';
                    errorMessage += '(' + msg + ')';
                }
            }
            _this.toastr.error(errorMessage, 'Info:', { timeOut: 2000 });
            console.log('POST error : ', error);
        });
    };
    EmployeeService.prototype.getEmployees = function () {
        var _this = this;
        var employees = new Subject();
        this.appService.getResource(this.employeeUrl)
            .subscribe(function (response) {
            console.log('GET request successful', response);
            _this.employeesSubject.next(response);
            employees.next(response);
        }, function (error) {
            console.log('GET error : ' + error);
        });
        return employees.asObservable();
    };
    EmployeeService.prototype.getEmployeeById = function (id) {
        var _this = this;
        return new Observable(function (observer) {
            _this.appService.getResource(_this.employeeUrl + id)
                .subscribe(function (response) {
                console.log('GET request successful', response);
                _this.selectedEmployeeSubject.next(response);
                observer.next(response);
            }, function (error) {
                _this.toastr.error('Ce salarié n\'existe plus !', '', {
                    timeOut: 2000
                });
                console.log('GET error : ', error);
            }, function () { return observer.complete(); });
        }).pipe(first());
    };
    EmployeeService.prototype.deleteEmployeeById = function (id) {
        var _this = this;
        return this.appService.deleteResource(this.employeeUrl + id)
            .subscribe(function (response) {
            var report = new Reporting(new Date(), 'SUPPRESSION EMPLOYE', _this.authService.user.trigram, _this.authService.user.idEmployee, 'Employe', id);
            _this.reportingService.createReport(report);
            console.log('DELETE request successful ', response);
            _this.toastr.info('Le salarié a été supprimé avec succès!', '', { timeOut: 2000 });
        }, function (error) {
            console.log('DELETE error : ', error);
            _this.toastr.error('Erreur lors de la suppression du salarié !', '', { timeOut: 2000 });
        });
    };
    EmployeeService.prototype.updateEmployee = function (id, employee) {
        var _this = this;
        return new Observable(function (observer) {
            return _this.appService.putResource(_this.employeeUrl + id, employee)
                .subscribe(function (response) {
                var report = new Reporting(new Date(), 'MISE A JOUR EMPLOYE', _this.authService.user.trigram, _this.authService.user.idEmployee, 'Employe', employee.id);
                _this.reportingService.createReport(report);
                console.log('PUT request successful', response);
                _this.toastr.info('Salarié mis à jour avec succès !', '', { timeOut: 2000 });
                observer.next(response);
            }, function (error) {
                var errorMessage = 'Erreur lors de la mise à jour du salarié ! ';
                var msg = error.error.message;
                if (error.status === 400) {
                    msg = msg.split('=').pop().split(',')[0];
                    errorMessage += '(' + msg + ')';
                }
                if (error.status === 401) {
                    msg = 'utilisateur non authentifié';
                    errorMessage += '(' + msg + ')';
                }
                if (error.status === 500 || error.status === 504 || error.status === 503) {
                    if (error.error.message.toString() === 'duplicated social Number') {
                        errorMessage = 'Le numéro de sécurité sociale doit etre unique.';
                    }
                    else {
                        msg = 'Une erreur côté serveur est survenue.Veuillez contacter la team-IT';
                        errorMessage += '(' + msg + ')';
                    }
                }
                observer.next(error);
                _this.toastr.error(errorMessage, 'Info:', { timeOut: 2000 });
                console.log('UPDATE error : ', error);
            }, function () {
                observer.complete();
            });
        });
    };
    EmployeeService.prototype.trimValidator = function (control) {
        if (control.value && control.value.startsWith(' ')) {
            return {
                trimError: { value: 'control has leading whitespace' }
            };
        }
        if (control.value && control.value.endsWith(' ')) {
            return {
                trimError: { value: 'control has trailing whitespace' }
            };
        }
        return null;
    };
    EmployeeService.prototype.getPDF = function (path) {
        return this.appService.getResourcePdf(environment.baseUrlTemplate + '/pdf/' + path);
    };
    EmployeeService.prototype.showPDF = function (path) {
        this.getPDF(path).subscribe(function (x) {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([x], { type: 'application/pdf' });
            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }
            var fileURL = window.URL.createObjectURL(newBlob);
            window.open(fileURL, '_blank');
        });
    };
    EmployeeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmployeeService_Factory() { return new EmployeeService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AppService), i0.ɵɵinject(i3.ToastrService), i0.ɵɵinject(i4.AuthService), i0.ɵɵinject(i5.ReportingService)); }, token: EmployeeService, providedIn: "root" });
    return EmployeeService;
}());
export { EmployeeService };
