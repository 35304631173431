import { ErrorStateMatcher } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AdminService } from '../../admin/shared/admin.service';
import { Router } from '@angular/router';
import { User } from '../../admin/shared/user.model';
import { UserRoleModel } from '../../admin/shared/userRole.model';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-first-login',
  templateUrl: './first-login.component.html',
  styleUrls: ['./first-login.component.scss']
})

export class FirstLoginComponent implements OnInit {

  form: FormGroup = new FormGroup({});
  user: User;
  idEmployee: number;
  userRoles: UserRoleModel[];
  matcher = new ErrorStateMatcher();
  showPassword = false;
  iconName = 'visibility';

  constructor(private adminService: AdminService,
              private authService: AuthService,
              private router: Router) {}

  ngOnInit() {
    this.user = this.authService.user;
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      oldPassword: new FormControl('', [Validators.required,  Validators.minLength(6)]),
      password: new FormControl('', [Validators.required,  Validators.minLength(6)] ),
      confirmPassword: new FormControl('')
    });
  }

  changeVisibility() {
    this.showPassword = !this.showPassword;
    this.iconName = this.showPassword ? 'visibility_off' : 'visibility';
  }

  resetPassword() {
    // update the user and redirect to home
    const data = {
      oldPassword: JSON.stringify(this.form.get('oldPassword').value),
      newPassword: JSON.stringify(this.form.get('password').value),
      login: localStorage.getItem('login')
    };
    localStorage.clear();
    this.adminService.resetPassword(data).subscribe((response) => {
      this.router.navigate(['/home']);
    });
  }

}
