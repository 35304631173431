import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
var NavigationService = /** @class */ (function () {
    function NavigationService(http, router) {
        this.http = http;
        this.router = router;
        this.menuJsonPath = '/assets/menus/menu.json';
        this.navRoutes = new Array();
    }
    // Get json menu from assets
    NavigationService.prototype.getAllMenu = function () {
        return this.http.get(this.menuJsonPath);
    };
    // remove nav item of navbar from component name
    NavigationService.prototype.removeItemByComponent = function (componentName, menu) {
        var found = menu.find(function (item) { return item.component === componentName; });
        // if item in array delete him
        return found ? menu.splice(menu.indexOf(found), 1) : menu;
    };
    NavigationService.prototype.filterMenu = function (menu, event) {
        var _this = this;
        return menu.filter(function (section) {
            return _this.replaceIdsinRoutes(section, event).
                find(function (it) { return it.routerLink === _this.router.url &&
                it.icon !== 'arrow_back'; });
        })[0];
    };
    // replace id in menu routes by good ids
    NavigationService.prototype.replaceIdsinRoutes = function (menu, event) {
        menu.forEach(function (item) {
            var itemRouterLink = item.routerLink;
            event.snapshot.paramMap.keys.forEach(function (key) {
                itemRouterLink = itemRouterLink.replace(":" + key, event.snapshot.paramMap.get(key));
            });
            item.routerLink = itemRouterLink;
        });
        return menu;
    };
    // clear nav content
    NavigationService.prototype.clearAllItems = function () {
        this.navRoutes = [];
    };
    // set nav content
    NavigationService.prototype.setNavItems = function (navRoutes) {
        this.navRoutes = navRoutes;
    };
    // get nav content
    NavigationService.prototype.getNavItems = function () {
        return this.navRoutes;
    };
    NavigationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavigationService_Factory() { return new NavigationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router)); }, token: NavigationService, providedIn: "root" });
    return NavigationService;
}());
export { NavigationService };
