import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent {

  title = 'Etes-vous sur?';
  confirmButtonText = 'Oui';
  cancelButtonText = 'Non';
  seeActual = 'Visualiser le contrat actuel';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<UploadDialogComponent>) {
      if (data) {
        this.title = data.message || this.title;
        if (data.buttonText) {
          this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
          this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
          this.seeActual = data.buttonText.seeActual || this.seeActual;
        }
     }
    }

  cancelDialog(): void {
    this.dialogRef.close();
  }
  confirmDialog(): void {
    this.dialogRef.close();
  }

  openDocument() {
    this.data.callback.pipe(take(1)).subscribe((x) => {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([x], { type: 'application/pdf' });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const fileURL = window.URL.createObjectURL(newBlob);
      window.open(fileURL, '_blank');
    });
  }


}
