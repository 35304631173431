import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AdminComponent} from './admin.component';
import {UsersListComponent} from './users-list/users-list.component';
import {AddUserComponent} from './add-user/add-user.component';
import {EditUserComponent} from './edit-user/edit-user.component';
import {AuthGuardService} from '../auth/shared/auth-guard.service';
import {ResetPasswordComponent} from './reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuardService],
        data: {permissions: ['ADMIN_USERS_LIST']},
        component: UsersListComponent,
      },
      {
        path: 'add',
        canActivate: [AuthGuardService],
        data: {permissions: ['ADMIN_CREATE_USER']},
        component: AddUserComponent
      },
      {
        path: 'edit/:idEmployee',
        canActivate: [AuthGuardService],
        data: {permissions: ['ADMIN_UPDATE_USER']},
        component: EditUserComponent
      },
    ]
  },
  {
    path: 'password',
    outlet: 'reset',
    component: ResetPasswordComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],

})
export class AdminRoutingModule {
}
