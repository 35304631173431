import { FirstLoginComponent } from './modules/auth/first-login/first-login.component';
import {NgModule} from '@angular/core';
import {NoPreloading, PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthComponent} from './modules/auth/auth.component';
import {NotFoundComponent} from './modules/not-found/not-found.component';
import {MainNavComponent} from './core/main-nav/main-nav.component';
import {AuthGuardService} from './modules/auth/shared/auth-guard.service';
import { ForgotPasswordComponent } from './modules/auth/forgot-password/forgot-password.component';

const appRoutes: Routes = [
  {path: 'auth', component: AuthComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'preco', canActivate: [AuthGuardService], component: FirstLoginComponent},

  {path: '',
    canActivate: [AuthGuardService],
    component: MainNavComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home'
      },
      {
        path: 'home',
        loadChildren: () => import('./modules/home/home.module').then(mod => mod.HomeModule)
      },
      {
        path: 'candidates',
        loadChildren: () => import('./modules/candidates/candidates.module').then(mod => mod.CandidatesModule),
      },
      {
        path: 'clients',
        loadChildren: () => import('./modules/clients/clients.module').then(mod => mod.ClientsModule),
      },
      {
        path: 'suppliers',
        loadChildren: () => import('./modules/suppliers/suppliers.module').then(mod => mod.SuppliersModule),
      },
      {
        path: 'employees',
        loadChildren: () => import('./modules/employees/employees.module').then( mod => mod.EmployeesModule),
      },
      {
        path: 'missionOrders',
        loadChildren: () => import('./modules/mission-orders/mission-orders.module').then( mod => mod.MissionOrdersModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(mod => mod.DashboardModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./modules/admin/admin.module').then(mod => mod.AdminModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then(mod => mod.SettingsModule)
      },
      {
        path: 'boondview',
        loadChildren: () => import('./modules/boondview/boondview.module').then(mod => mod.BoondviewModule)
      }
    ],
  },
  {path: 'not-found', component: NotFoundComponent},
  {path: '**', redirectTo: 'not-found'},
];




@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
exports: [RouterModule]
})



export class AppRoutingModule { }
