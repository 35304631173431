import { OnInit } from '@angular/core';
import { EmployeeService } from '../../employees/shared/employee.service';
import { AdminService } from '../shared/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { UserRoleModel } from '../shared/userRole.model';
import { DataService } from 'src/app/shared/data.service';
var EditUserComponent = /** @class */ (function () {
    function EditUserComponent(formBuilder, employeeService, adminService, route, router, dataService) {
        this.formBuilder = formBuilder;
        this.employeeService = employeeService;
        this.adminService = adminService;
        this.route = route;
        this.router = router;
        this.dataService = dataService;
        this.resetPassword = false;
        this.idEmployee = Number(this.route.snapshot.paramMap.get('idEmployee'));
    }
    EditUserComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getUserRoles();
        forkJoin([
            this.employeeService.getEmployeeById(this.idEmployee).pipe(first()),
            this.adminService.getUserById(this.idEmployee).pipe(first())
        ]).subscribe(function (_a) {
            var employee = _a[0], user = _a[1];
            _this.user = user;
            _this.employee = employee;
            _this.initForm();
            _this.adminService.resourcesLoaded = true;
        });
    };
    EditUserComponent.prototype.getUserRoles = function () {
        var _this = this;
        this.dataService.getUserRoles().subscribe(function (userRoles) {
            _this.userRoles = userRoles.userRoles.filter(function (r) { return r.name !== 'ROLE_PRESIDENT'; });
        });
    };
    EditUserComponent.prototype.getUserRole = function () {
        var _this = this;
        return this.userRoles.find(function (role) { return _this.user.role === role.name; });
    };
    EditUserComponent.prototype.changeAccountLocked = function ($event) {
        this.editUserForm.get('accountLocked').setValue($event.checked);
    };
    EditUserComponent.prototype.addValidatorOnPassword = function (reset) {
        this.resetPassword = !reset;
        if (this.resetPassword) {
            this.editUserForm.addControl('password', new FormControl('', [Validators.required, Validators.minLength(6)]));
            this.editUserForm.addControl('confirmPassword', new FormControl(''));
        }
        else {
            this.editUserForm.setValidators(null);
            this.editUserForm.removeControl('password');
            this.editUserForm.removeControl('confirmPassword');
        }
    };
    EditUserComponent.prototype.editUser = function () {
        var _this = this;
        // Create user and redirect to users list
        var user = Object.assign({}, this.editUserForm.getRawValue());
        user.role = new UserRoleModel(this.editUserForm.get('role').value.id, this.editUserForm.get('role').value.name);
        user.idEmployee = this.idEmployee;
        user.enable = this.user.enable;
        this.adminService.updateUser(user, user.idEmployee).subscribe(function () {
            _this.router.navigate(['/users']);
        });
    };
    EditUserComponent.prototype.initForm = function () {
        this.editUserForm = this.formBuilder.group({
            username: [this.user.username],
            trigram: [this.user.trigram],
            accountLocked: [this.user.accountLocked],
            role: [this.getUserRole(), [Validators.required]]
        });
    };
    EditUserComponent.prototype.cancel = function () {
        this.router.navigate(['/users']);
    };
    return EditUserComponent;
}());
export { EditUserComponent };
