import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Lot} from '../../../modules/andrice-offers/shared/lot.model';
import {DatePipe} from '@angular/common';
import {AndriceOfferService} from '../../../modules/andrice-offers/shared/andrice-offer.service';
import {WorkingDay} from '../../../modules/andrice-offers/shared/working-day.model';
import {DateOperationUtil} from '../../utils/date-utils';

@Component({
  selector: 'app-tjm-lot-dialog',
  templateUrl: './tjm-lot-dialog.component.html',
  styleUrls: ['./tjm-lot-dialog.component.scss']
})
export class TjmLotDialogComponent implements OnInit {

  tjmForm: FormGroup;
  workingDays: Array<WorkingDay> = new Array<WorkingDay>();
  matcher: any;
  lot: Lot ;
  monthAndYear: string[] = [];
  moneyType="€";


  constructor(
    public dialogRef: MatDialogRef<TjmLotDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private andriceOfferService: AndriceOfferService) { }

  ngOnInit() {
    this.lot = new Lot();
    console.log(this.data.message);
    this.lot = this.data.message;
    this.moneyType = this.data.currency;
    let newWorkingday: WorkingDay;
    // recuperation de la liste des workingDays qqu'on ajoute a notre liste
    for (const workingday of this.lot.workingDaysList) {
      newWorkingday = new WorkingDay();
      newWorkingday.nbDays = workingday.nbDays;
      newWorkingday.idLot = this.lot.id;
      newWorkingday.id = workingday.id;
      newWorkingday.amount = workingday.amount;
      newWorkingday.month = workingday.month;
      newWorkingday.ordre = workingday.ordre;
      this.workingDays.push(newWorkingday);
    }
    // si la liste est vide alors on calcule le nombre de jours ouvres durant la periode
    if (this.workingDays.length === 0) {
      this.getWorkingDayJo(this.lot.startDate, this.lot.endDate);
     } else {
      // sinon on recupere les mois de ce lot pour l'affichage
       this.getAllMonth(this.lot.startDate, this.lot.endDate);
     }

    this.tjmForm = this.formBuilder.group({
       nbDays: new FormControl('')
    });

  }

  confirmDialog() {
    console.log('confirm dialog');
    this.lot.workingDaysList = [];
    let newWorkingday: WorkingDay;
    // Si on valide la modification alors o recupere les differents elements de notre liste qu'on ajoute à notre lot
    for (const workingday of this.workingDays) {
      newWorkingday = new WorkingDay();
      newWorkingday.nbDays = workingday.nbDays;
      newWorkingday.id = workingday.id;
      newWorkingday.idLot = workingday.idLot;
      newWorkingday.amount = workingday.amount;
      newWorkingday.ordre = workingday.ordre;
      newWorkingday.month = workingday.month;
      this.lot.workingDaysList.push(newWorkingday);
    }
    this.dialogRef.close(this.lot);
  }

  updateWorkingDay(ev: any, index: number) {
    // remplacement de a valeur de notre working day dans la liste lors de la modification
    this.workingDays[index].nbDays = Number(this.tjmForm.get('nbDays').value);
  }

  cancel() {
  // lorsqu'on annule la modification, aucun changement n'est enregistre
    this.dialogRef.close();

  }

  getAllMonth(firstDate: Date, secondDate: Date) {
    // recuperation de la liste des mois entre une intervalle de date
    const months: number[] = [];
    let nbMonth = 0;
    if (firstDate.getFullYear() === secondDate.getFullYear()) {
      // si on a la meme annee pour la date de debut et la date de fin, le nombre de
      // mois sera la difference entre le mois de la date de fin et celui de la date de debut
      nbMonth = secondDate.getMonth() - firstDate.getMonth();

    } else {
      // si nous avons des annees differentes alors on recupere les 2 annees
      // et tant que i est != de la deuxieme annee on fait la difference entre le 12mois et le mois de la premiere date et
      // notre nouvelle date passe au premier jour de l'annee suivante soit le 1 janvier
      // qi i est === a l'annee de la seconde date alors on recupere juste le mois de la seconde anee +1 qu'on ajoute a notre nombre de mois

      // nbMonth = Math.abs(Math.round(diff));
      const firstYear = Number(firstDate.getFullYear());
      let usedFirstDay = firstDate;
      const secondYear = Number(secondDate.getFullYear());

      for (let i = firstYear; i <= secondYear; i++) {
        // si les 2 dates sont de la meme année alors le nombre de mois est la
        // difference entre le mois de la seconde date et celui de la premiere
        // sinon on aura 12 - le mois de la seconde année
        if (i !== secondYear) {
          nbMonth += 11 - usedFirstDay.getMonth();
          usedFirstDay = new Date(i + 1, 1, 1);
        } else {

          nbMonth += secondDate.getMonth() + 1;
        }
      }

    }
    let date = firstDate;
    for (let i = 0; i <= nbMonth; i++) {
      months.push(date.getMonth());
      this.getMonthAndYear(date);
      date = DateOperationUtil.getFirstDayOfNextMonth(date);
    }
    return months;

  }


  /*
  retourne une lite comprenant les ois et les annees correspondant
  */
  getMonthAndYear(date: Date) {

    const month = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    const result: string = month[date.getMonth()] + ' ' + date.getFullYear();
    this.monthAndYear.push(result);
  }

  getWorkingDayJo(firstDate: Date, secondDate: Date) {
    // get month list
    const months: number[] = this.getAllMonth(firstDate, secondDate);
    // tslint:disable-next-line:label-position prefer-const
    let workingDay: WorkingDay;
    let firstDayNextMonth: Date = null;
    // parcours de la liste contenant le numero des mois compris entre la premiere et la deuxieme date
    for ( let i = 0; i < months.length; i++) {
      workingDay = new WorkingDay();
      workingDay.month = months[i] + 1;
      workingDay.amount = this.lot.mdr;
      workingDay.id = null;
      // pour le premier mois calculer le nombre de jours ouvrés entre cette date et le dernier jour du mois (si mois different)
      // sinon calculer la difference entre la premiere date et la deuxieme date
      if (i === 0 ) {

        workingDay.nbDays = DateOperationUtil.getWorkingDays(firstDate, DateOperationUtil.getEndDate(firstDate, secondDate));

        firstDayNextMonth = DateOperationUtil.getFirstDayOfNextMonth(firstDate);
        // this.getMonthAndYear(firstDate);
      } else {
      // sinon calculer la difference entre le premier jour du mois suivant et le dernier jour du mois
        workingDay.nbDays = DateOperationUtil.getWorkingDays(
          firstDayNextMonth, DateOperationUtil.getEndDate(firstDayNextMonth, secondDate));

        // this.getMonthAndYear(firstDayNextMonth);

        firstDayNextMonth = DateOperationUtil.getFirstDayOfNextMonth(DateOperationUtil.getEndDate(firstDayNextMonth, secondDate));
      }
      this.workingDays.push(workingDay);
    }
  }


}
