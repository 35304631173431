<form *ngIf="employee && user" [formGroup]="editUserForm">
  <div class="container-fluid">
    <!--<div class="row">
      <div class="col-md-12">
        <mat-card class="msg">
          {{ employee.firstName }} {{employee.lastName}}
        </mat-card>
      </div>
    </div>-->
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div class="form-group row">
          <mat-form-field>
            <input matInput placeholder="Nom d'utilisateur" formControlName="username" [errorStateMatcher]="matcher" appDirNormalize >
          </mat-form-field>
        </div>
        <div class="form-group row">
          <mat-form-field>
            <input matInput placeholder="Trigram" formControlName="trigram"
                   [errorStateMatcher]="matcher" appDirUppercase appDirNormalize readonly>
          </mat-form-field>
        </div>
        <div class="form-group row arrowButton" *ngIf="!resetPassword">
          <button type="button" (click)="addValidatorOnPassword(resetPassword)" mat-raised-button class="addButton">
            <strong>Créer un nouveau mot de passe</strong>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
        </div>
        <div class="form-group row arrowButton" *ngIf="resetPassword">
          <button type="button" (click)="addValidatorOnPassword(resetPassword)" mat-raised-button class="deleteButton">
            <mat-icon>keyboard_arrow_up</mat-icon>
          </button>
        </div>
        <app-reset-password *ngIf="resetPassword" [user]="user" [form]="editUserForm"></app-reset-password>
      </div>

      <div class="col-md-6 col-sm-6">
        <div class="form-group row">
          <mat-form-field>
            <mat-label>Role</mat-label>
            <mat-select formControlName="role" [errorStateMatcher]="matcher" required>
              <mat-option *ngFor="let role of userRoles" [value]="role">{{role.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="editUserForm.get('role').hasError('required')">Le role est
              <strong>requis</strong> !
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group row accountBlocked">
          <mat-checkbox formControlName="accountLocked" (change)="changeAccountLocked($event)">
            Utilisateur bloqué</mat-checkbox>
        </div>
      </div>
    </div>
  </div>
</form>

<div *ngIf="editUserForm" class="container-fluid">
  <div class="row">
    <div class="col-sm text-right">
      <button mat-raised-button class="avoidButton"
              (click)="cancel()">
        <mat-icon>clear</mat-icon>
        <strong> ANNULER</strong>
      </button>
      <button mat-raised-button class="invalidButton"
              [hidden]="editUserForm.valid" disabled>
        <mat-icon>error_outline</mat-icon>
        <strong> UTILISATEUR INVALIDE</strong>
      </button>
      <button type="button" mat-raised-button class="addButton"
              [hidden]="!editUserForm.valid"
              (click)="editUser()">
        <mat-icon>done</mat-icon>
        <strong> MODIFIER UTILISATEUR</strong>
      </button>
    </div>
  </div>
</div>
