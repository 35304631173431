import { Component, OnInit } from '@angular/core';
import {EmployeeService} from '../../employees/shared/employee.service';
import {AdminService} from '../shared/admin.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {forkJoin} from 'rxjs';
import {first} from 'rxjs/operators';
import {Employee} from '../../employees/shared/employee.model';
import {UserRoleModel} from '../shared/userRole.model';
import {User} from '../shared/user.model';
import {UserToPersistModel} from '../shared/userToPersist.model';
import { DataService } from 'src/app/shared/data.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  user: User;
  idEmployee: number;
  editUserForm: FormGroup;
  employee: Employee;
  matcher: any;
  userRoles: UserRoleModel[];
  resetPassword = false;

  constructor(
    private formBuilder: FormBuilder,
    private employeeService: EmployeeService,
    private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService
  ) {
    this.idEmployee = Number(this.route.snapshot.paramMap.get('idEmployee'));
  }

  ngOnInit() {
    this.getUserRoles();
    forkJoin([
      this.employeeService.getEmployeeById(this.idEmployee).pipe(first()),
      this.adminService.getUserById(this.idEmployee).pipe(first())
    ]).subscribe(([employee, user]) => {
      this.user = user;
      this.employee = employee;
      this.initForm();
      this.adminService.resourcesLoaded = true;
    });
  }

  getUserRoles() {
    this.dataService.getUserRoles().subscribe((userRoles) => {
      this.userRoles = userRoles.userRoles.filter((r: UserRoleModel ) => r.name !== 'ROLE_PRESIDENT');
    });
  }

  getUserRole() {
    return  this.userRoles.find((role) => this.user.role === role.name);
  }

  changeAccountLocked($event) {
    this.editUserForm.get('accountLocked').setValue($event.checked);
  }

  addValidatorOnPassword(reset: boolean) {
    this.resetPassword = !reset;
    if (this.resetPassword) {
      this.editUserForm.addControl('password', new FormControl('', [Validators.required,  Validators.minLength(6)] ));
      this.editUserForm.addControl('confirmPassword', new FormControl(''));
    } else {
      this.editUserForm.setValidators(null);
      this.editUserForm.removeControl('password');
      this.editUserForm.removeControl('confirmPassword');
    }
  }

  editUser() {
    // Create user and redirect to users list
    const user: UserToPersistModel =  Object.assign({}, this.editUserForm.getRawValue());
    user.role = new UserRoleModel(this.editUserForm.get('role').value.id, this.editUserForm.get('role').value.name);
    user.idEmployee = this.idEmployee;
    user.enable = this.user.enable;
    this.adminService.updateUser(user, user.idEmployee).subscribe(() => {
      this.router.navigate(['/users']);
    });
  }

  initForm() {
    this.editUserForm = this.formBuilder.group({
      username: [this.user.username],
      trigram: [this.user.trigram],
      accountLocked: [this.user.accountLocked],
      role: [ this.getUserRole(), [Validators.required]]
    });
  }

  cancel() {
    this.router.navigate(['/users']);
  }

}
