import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AppService} from '../../../shared/app.service';
import {AdminService} from '../shared/admin.service';
import {UserToPersistModel} from '../shared/userToPersist.model';
import {UserRoleModel} from '../shared/userRole.model';
import {Employee} from '../../employees/shared/employee.model';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {EmployeeService} from '../../employees/shared/employee.service';
import {first, map, startWith} from 'rxjs/operators';
import {User} from '../shared/user.model';
import { DataService } from 'src/app/shared/data.service';


@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit, OnDestroy {
  addUserForm: FormGroup;
  matcher: any;
  employees: Employee[];
  users: User[] ;
  newEmployeeList: Employee[] = [];
  subscription: Subscription;
  userRoles: UserRoleModel[];
  employeeFilter = new FormControl('');
  filteredEmployees: Observable<Employee[]>;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              public appService: AppService,
              private adminService: AdminService,
              private employeeService: EmployeeService,
              private dataService: DataService
              ) {
  }

  ngOnInit() {
    this.addUserForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      trigram: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6),
        Validators.maxLength(20)]],
      role: ['', [Validators.required]],
      employee: ['', [Validators.required]],
      confirmPassword: ['']
    }, {validator: this.checkPasswords});

    this.dataService.getUserRoles().subscribe((userRoles) => {
      this.userRoles = userRoles.userRoles.filter((r: UserRoleModel ) => r.name !== 'ROLE_PRESIDENT');
    });

    //
    // Get employees and users to filter employees and display those who doesn't have user account
    //

    this.subscription = forkJoin([
      this.employeeService.getEmployees().pipe(first()),
      this.adminService.getUsers().pipe(first())
    ]).subscribe(([employees, users]) => {
      this.employees = employees;
      this.users = users;
      this.employees.forEach((employee) => {
        const userfound = this.users.find(user => user.idEmployee === employee.id);
        if (userfound === undefined && (employee.family === 'Siège' || employee.family === 'Business')) {
          this.newEmployeeList.push(employee);
        }
      });
      this.newEmployeeList.sort((a, b) => {
        return a.firstName > b.firstName ? 1 : -1;
      });
      this.filteredEmployees = this.employeeFilter.valueChanges
        .pipe(startWith(''), map(value => this.appService.employeesFilter(this.newEmployeeList, value)));
      this.adminService.resourcesLoaded = true;
    });
  }

  get password() {
    return this.addUserForm.get('password').value;
  }

  get confirmPassword() {
    return this.addUserForm.get('confirmPassword').value;
  }

  /* Called on each input in either password field */
  // here we have the 'passwords' group
  checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : { notSame: true };
  }
  reseatEmployeeSearchField() {
    this.employeeFilter = new FormControl('');
    this.filteredEmployees = this.employeeFilter.valueChanges
      .pipe(startWith(''), map(value => this.appService.employeesFilter(this.newEmployeeList, value)));
  }

  selectEmployee(employee: Employee) {
    this.addUserForm.get('username').setValue(employee.workEmail);
    this.addUserForm.get('trigram').setValue(employee.trigram);
    this.addUserForm.get('password').setValue(employee.trigram + '20andrice');
    this.addUserForm.get('confirmPassword').setValue(employee.trigram + '20andrice');
  }

  onSubmitForm() {
    // Create user and redirect to users list
    const user: UserToPersistModel =  Object.assign({}, this.addUserForm.getRawValue());
    user.role = new UserRoleModel(this.addUserForm.get('role').value.id, this.addUserForm.get('role').value.name);
    user.accountLocked = false;
    user.enable = true;
    const employeeId: number = Number(this.addUserForm.get('employee').value);
    user.idEmployee = employeeId;
    this.adminService.createUser(user).subscribe(() => {
      this.router.navigate(['/users']);
    });
  }

  cancel() {
    this.router.navigate(['/users']);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
