<body>
    <div class="stars">
        <div class="custom-navbar">
            <div class="brand-logo">
                <img src="/assets/img/logo_blanc.png" width="6%" />
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <div class="card card-signin my-5">
                        <div class="card-body">
                            <h5 class="card-title text-center"> Veuillez changer votre mot de passe par défaut</h5>
                            <form [formGroup]="form" class="form-signin" name="form">
                                <div class="form-group row">
                                    <mat-form-field>
                                        <input matInput maxlength="20" [type]="showPassword ? 'text' : 'password'" [errorStateMatcher]="matcher" placeholder="Mot de passe actuel" formControlName="oldPassword" required />
                                        <button mat-button matSuffix class="viewButton" (click)="changeVisibility()">
                                            <mat-icon>{{ iconName }}</mat-icon>
                                        </button>
                                        <mat-error *ngIf="form.get('oldPassword').hasError('required')">
                                            Le mot de passe est <strong>requis</strong> !
                                        </mat-error>
                                        <mat-error *ngIf="form.get('oldPassword').hasError('minlength') &&
                                            !form.get('oldPassword').hasError('required') ">
                                            Le mot de passe introduit est trop court !
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <app-reset-password [user]="user" [form]="form"></app-reset-password>

                                <button class="btn btn-lg btn-primary btn-block text-uppercase" [disabled]="form.invalid" (click)="resetPassword()">
                  Changer le mot de passe
                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="objects">
            <div class="earth-moon">
                <img class="object_earth" src="/assets/img/404/planete.svg" width="5%" />
                <img class="object_moon" src="/assets/img/404/moon.svg" width="4%" />
            </div>
        </div>
        <div class="glowing_stars">
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
        </div>
    </div>
</body>
