import {Directive, ElementRef, HostListener} from '@angular/core';
import {normalize} from 'normalize-diacritics';

@Directive({
  selector: '[appDirNormalize]'
})
/*
Delete accent
*/
export class DirNormalizeDirective {

  constructor(public ref: ElementRef) {
  }

  @HostListener('input', ['$event'])
  async onInput(event) {

    // event.target.value = await normalize(this.ref.nativeElement.value);
    event.target.value = this.ref.nativeElement.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

}
