import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';
import { AppService } from '../../../../shared/app.service';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../../../shared/app.service";
var ReportingService = /** @class */ (function () {
    function ReportingService(router, appService) {
        this.router = router;
        this.appService = appService;
        this.historyUrl = environment.baseUrlOauth + '/reporting/';
    }
    // Create the report by saving it in the database
    ReportingService.prototype.createReport = function (report) {
        this.appService.postResource(this.historyUrl + 'add', report).subscribe(function (response) {
            console.log('create report');
        }, function (error) {
            console.log('error create report');
        });
    };
    // getting all saved report in the database
    ReportingService.prototype.getHistories = function () {
        var clients = new Subject();
        return this.appService.getResource(this.historyUrl + 'all');
    };
    // update a report
    ReportingService.prototype.updateReport = function (report) {
        this.appService.putResource(this.historyUrl, report).subscribe(function (response) {
        }, function (error1) {
            console.log('update history error');
        });
    };
    // delete a report
    ReportingService.prototype.deleteReportById = function (id) {
        this.appService.deleteResource(this.historyUrl + id).subscribe(function (response) {
        }, function (error) {
            console.log('DELETE history error : ', error);
        });
    };
    ReportingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReportingService_Factory() { return new ReportingService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AppService)); }, token: ReportingService, providedIn: "root" });
    return ReportingService;
}());
export { ReportingService };
