import { AuthService } from './../modules/auth/shared/auth.service';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../modules/auth/shared/auth.service";
var DataService = /** @class */ (function () {
    function DataService(http, authService) {
        this.http = http;
        this.authService = authService;
        this.dataUrl = '/assets/data';
    }
    DataService.prototype.getCountries = function () {
        return this.http.get(this.dataUrl + "/nationalities.json");
    };
    DataService.prototype.getEmployeeData = function () {
        return this.http.get(this.dataUrl + "/employeeData.json");
    };
    DataService.prototype.getContactData = function () {
        return this.http.get(this.dataUrl + "/contactData.json");
    };
    DataService.prototype.getClientData = function () {
        return this.http.get(this.dataUrl + "/clientData.json");
    };
    DataService.prototype.getAndriceOfferData = function () {
        return this.http.get(this.dataUrl + "/andriceOfferData.json");
    };
    DataService.prototype.getUserRoles = function () {
        return this.http.get(this.dataUrl + "/user_role.json");
    };
    DataService.prototype.getTimeData = function () {
        return this.http.get(this.dataUrl + "/timeData.json");
    };
    DataService.prototype.getSupplierType = function () {
        return this.http.get(this.dataUrl + "/supplierData.json");
    };
    DataService.prototype.defineInfosAndrice = function (form, family) {
        var families = [''];
        switch (this.authService.user.role) {
            case 'ROLE_RH':
                if (family !== 'Ingénierie') {
                    families = ['Siège', 'Business'];
                }
                else {
                    families = ['Ingénierie'];
                    form.get('family').disable();
                    form.get('category').disable();
                    form.get('status').disable();
                    form.get('schoolYear').disable();
                    form.get('position').disable();
                    form.get('businessUnit').disable();
                }
                break;
            case 'ROLE_RRH':
                if (family !== 'Ingénierie') {
                    families = ['Siège', 'Business'];
                }
                else {
                    families = ['Ingénierie'];
                    form.get('family').disable();
                    form.get('category').disable();
                    form.get('status').disable();
                    form.get('schoolYear').disable();
                    form.get('position').disable();
                    form.get('businessUnit').disable();
                }
                break;
            case 'ROLE_BM_SENIOR':
                if (family === 'Ingénierie') {
                    families = ['Ingénierie'];
                }
                else {
                    form.get('family').disable();
                    form.get('category').disable();
                    form.get('status').disable();
                    form.get('schoolYear').disable();
                    form.get('position').disable();
                    form.get('businessUnit').disable();
                }
                break;
            case 'ROLE_BM_JUNIOR':
                if (family === 'Ingénierie') {
                    families = ['Ingénierie'];
                }
                else {
                    form.get('family').disable();
                    form.get('category').disable();
                    form.get('status').disable();
                    form.get('schoolYear').disable();
                    form.get('position').disable();
                    form.get('businessUnit').disable();
                }
                break;
            case 'ROLE_PRESIDENT':
                families = ['Siège', 'Business', 'Ingénierie'];
                break;
            default:
                families = ['Siège', 'Business', 'Ingénierie'];
                break;
        }
        return families.sort();
    };
    DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: DataService, providedIn: "root" });
    return DataService;
}());
export { DataService };
