import * as tslib_1 from "tslib";
import { ElementRef } from '@angular/core';
var DirNormalizeDirective = /** @class */ (function () {
    function DirNormalizeDirective(ref) {
        this.ref = ref;
    }
    DirNormalizeDirective.prototype.onInput = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                // event.target.value = await normalize(this.ref.nativeElement.value);
                event.target.value = this.ref.nativeElement.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                return [2 /*return*/];
            });
        });
    };
    return DirNormalizeDirective;
}());
export { DirNormalizeDirective };
