<div *ngIf="listUsers" class="container-fluid">
  <div class="table-responsive" *ngIf="dataSource.data.length !==0">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtre">
    </mat-form-field>

    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="_userName">
        <th mat-header-cell *matHeaderCellDef> Nom d'utilisateur </th>
        <td mat-cell *matCellDef="let user"> {{user.username}} </td>
      </ng-container>

      <ng-container matColumnDef="_enable">
        <th mat-header-cell *matHeaderCellDef> État d'activation </th>
        <td mat-cell *matCellDef="let user">
          <mat-checkbox disabled="true" [checked]="user.enable"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="_accountLocked">
        <th mat-header-cell *matHeaderCellDef> Utilisateur bloqué </th>
        <td mat-cell *matCellDef="let user">
          <mat-checkbox disabled="true" [checked]="user.accountLocked"></mat-checkbox> </td>
      </ng-container>

      <ng-container matColumnDef="_role">
        <th mat-header-cell *matHeaderCellDef> Rôle </th>
        <td mat-cell *matCellDef="let user"> {{user.role}} </td>
      </ng-container>

      <ng-container matColumnDef="_trigram">
        <th mat-header-cell *matHeaderCellDef> Trigram </th>
        <td mat-cell *matCellDef="let user">{{user.trigram}}</td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let user">
          <button mat-button class="editButton"
                  (click)="editUser(user)">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>
x
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let user">
          <button mat-button class="deleteButton"
                  (click)="deleteUser(user)">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div *ngIf="dataSource.data.length === 0">
    <mat-card class="msg">
      Il n'y a aucun compte utilisateur !
    </mat-card>
  </div>
</div>

