import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NavigationService} from '../shared/navigation.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

 @Input() loaded: boolean;

  constructor(public navigationService: NavigationService , public router: Router) { }

  ngOnInit() {
  }

}
