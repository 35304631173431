<div>
  <div class="content-container" *ngIf="lot != null">
<!--    <mat-icon id="close-icon" (click)="close()">close</mat-icon>-->
    <span class="content-span toast-bottom-full-width">
      <form [formGroup]="tjmForm" >
      <h1 mat-dialog-title>Lot & Taux Journalier Moyen</h1>
      <mat-dialog-content>
        <div class="row">
          <table class="table table-hover details">
                <thead>
                <tr>
                  <th>Mois</th>
                  <th>Jours ouvrés</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let workingday of workingDays; let i = index">
                  <td>{{monthAndYear[i]}}</td>
                  <td>
                    <mat-form-field>
                      <input matInput formControlName="nbDays" placeholder="Jours ouvrés"  [value]="workingday.nbDays" >
                    </mat-form-field>

                  </td>
                  <td></td>
                </tr>

                </tbody>
              </table>

        </div>
      </mat-dialog-content>
      <mat-dialog-actions>
          <button mat-raised-button id="no-button" (click)="close()"><mat-icon>cancel</mat-icon>  Fermer</button>
      </mat-dialog-actions>
    </form>
    </span>
  </div>
</div>
