import { NgModule } from '@angular/core';
import {CommonModule, CurrencyPipe, DatePipe} from '@angular/common';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {RouterModule, Routes} from '@angular/router';

import {MainNavComponent} from './main-nav/main-nav.component';
import {MaterialModule} from '../shared/material.module';
import {NavigationService} from './shared/navigation.service';
import {AuthModule} from '../modules/auth/auth.module';
import {HttpClientModule} from '@angular/common/http';
import {FrenchPaginatorIntl} from '../shared/utils/french-paginator-intl';
import {CookieService} from 'ngx-cookie-service';
import {ConfigService} from '../config/config.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {interceptorProviders} from '../shared/http-interceptors/interceptor-providers';
import {NgxSpinnerModule} from 'ngx-spinner';
import { DataService } from '../shared/data.service';



@NgModule({
  declarations: [
    MainNavComponent,
  ],
  exports: [
    MainNavComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    AuthModule,
    HttpClientModule,
    NgxSpinnerModule
  ],
  providers: [NavigationService,
    CookieService,
    interceptorProviders,
    ConfigService,
    DataService
  ]
})
export class CoreModule { }
