<body class="bg-blue">
<div class="stars">
  <div class="custom-navbar">
    <div class="brand-logo">
      <img  [routerLink]="['/home']"  src="/assets/img/logo_blanc.png" width="6%">
    </div>
    <div class="navbar-links">
        <ul>
          <li><a class="btn-request" [routerLink]="['/auth']">Se connecter</a></li>
        </ul>
    </div>
  </div>
  <div class="container">
    <div class="central-body">
      <div class="text-container">
        <h2 class="main-text">Mot de passe oublié?</h2>
        <p>Vous pouvez réinitialiser votre mot de passe ici.</p>
        <p></p>
      </div>
      <form class="forgot-form" [formGroup]="resetForm" (ngSubmit)="ResetPassword()">
        <div class="form-group row">
            <input type="email" class="form-control " placeHolder="Adresse Email" formControlName="email">
        </div>
        <div class="form-group">
          <button  class=" btn btn-go-home btn-block" type="submit">Réinitialiser</button>
        </div>
        
      </form>
    </div>
  </div>
  <div class="objects">
    <div class="earth-moon">
      <img class="object_earth" src="/assets/img/404/planete.svg" width="5%">
      <img class="object_moon" src="/assets/img/404/moon.svg" width="4%">
    </div>
  </div>

</div>

</body>
