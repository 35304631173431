import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
import * as i3 from "@angular/router";
var AppService = /** @class */ (function () {
    function AppService(httpClient, cookie, router) {
        this.httpClient = httpClient;
        this.cookie = cookie;
        this.router = router;
        // tslint:disable-next-line:max-line-length
        this.socialNumberMask = [/[1278]/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/];
    }
    AppService.prototype.logout = function () {
        this.cookie.deleteAll();
        this.router.navigate(['/auth']);
    };
    // permet de get les ressources sur le micro service souhaité
    AppService.prototype.getResource = function (resourceUrl) {
        var headers = new HttpHeaders({
            'Content-type': 'application/json; charset=utf-8',
            Authorization: 'Bearer ' + this.cookie.get('access_token')
        });
        var options = {
            headers: headers
        };
        return this.httpClient.get(resourceUrl, options);
    };
    AppService.prototype.getResourcePdf = function (resourceUrl) {
        var headers = new HttpHeaders({
            'Content-type': 'application/json; charset=utf-8',
            Authorization: 'Bearer ' + this.cookie.get('access_token')
        });
        return this.httpClient.get(resourceUrl, { headers: headers, responseType: 'blob' });
    };
    AppService.prototype.postResource = function (resourceUrl, object) {
        var headers = new HttpHeaders({
            'Content-type': 'application/json; charset=utf-8',
            Authorization: 'Bearer ' + this.cookie.get('access_token')
        });
        return this.httpClient.post(resourceUrl, object, {
            headers: headers,
            observe: 'response'
        });
    };
    AppService.prototype.uploadResource = function (resourceUrl, file, fileName) {
        var headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.cookie.get('access_token')
        });
        var formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', fileName);
        return this.httpClient.post(resourceUrl, formData, {
            headers: headers,
            observe: 'response'
        });
    };
    AppService.prototype.putResource = function (resourceUrl, object) {
        var headers = new HttpHeaders({
            'Content-type': 'application/json; charset=utf-8',
            Authorization: 'Bearer ' + this.cookie.get('access_token')
        });
        return this.httpClient.put(resourceUrl, object, {
            headers: headers,
            observe: 'response'
        });
    };
    AppService.prototype.deleteResource = function (resourceUrl) {
        var headers = new HttpHeaders({
            'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            Authorization: 'Bearer ' + this.cookie.get('access_token')
        });
        return this.httpClient.delete(resourceUrl, {
            headers: headers,
            observe: 'response'
        });
    };
    AppService.prototype.filterResearch = function (allValues, value) {
        var filterValue = value.toLowerCase();
        return allValues.filter(function (option) { return option.toLowerCase().includes(filterValue); }).sort();
    };
    AppService.prototype.filterResearchWithoutSort = function (allValues, value) {
        var filterValue = value.toLowerCase();
        return allValues
            .filter(function (option) { return option.toLowerCase().includes(filterValue); });
    };
    AppService.prototype.contactsFilter = function (allValues, value) {
        var filterValue = value.toLowerCase();
        return allValues
            .filter(function (option) { return option.firstName.toLowerCase().includes(filterValue) ||
            option.lastName.toLowerCase().includes(filterValue); })
            .sort();
    };
    AppService.prototype.employeesFilter = function (allValues, value) {
        var filterValue = value.toLowerCase();
        return allValues
            .filter(function (option) { return option.firstName.toLowerCase().includes(filterValue) ||
            option.lastName.toLowerCase().includes(filterValue); })
            .sort();
    };
    AppService.prototype.numberOnlyOnFields = function (event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    };
    AppService.prototype.allowDotOnNumericFields = function (event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
            return false;
        }
        return true;
    };
    AppService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppService_Factory() { return new AppService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService), i0.ɵɵinject(i3.Router)); }, token: AppService, providedIn: "root" });
    return AppService;
}());
export { AppService };
