import { Observable, Subject } from 'rxjs';
import { AppService } from '../../../shared/app.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DateOperationUtil } from '../../../shared/utils/date-utils';
import { first } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Reporting } from '../../auth/shared/reporting/reporting.model';
import { AuthService } from '../../auth/shared/auth.service';
import { ReportingService } from '../../auth/shared/reporting/reporting.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../../shared/app.service";
import * as i3 from "ngx-toastr";
import * as i4 from "../../auth/shared/auth.service";
import * as i5 from "../../auth/shared/reporting/reporting.service";
var AndriceOfferService = /** @class */ (function () {
    function AndriceOfferService(router, appService, toastr, authService, reportingService) {
        this.router = router;
        this.appService = appService;
        this.toastr = toastr;
        this.authService = authService;
        this.reportingService = reportingService;
        this.andriceOfferUrl = environment.baseUrlAndriceOffer + '/andriceoffers/';
        this.jobUrl = environment.baseUrlAndriceOffer + '/jobs/';
        this.lotUrl = environment.baseUrlAndriceOffer + '/lots/';
        this.workingDaysUrl = environment.baseUrlAndriceOffer + '/workingdays/';
        this.resourcesLoaded = false;
        // function to filter dates disable weekend and french holidays in the datepicker
        this.weekEndFilter = function (d) {
            var day = d.getDay();
            var holidays = [];
            var yearsList = [];
            var minYear = d.getFullYear() - 1;
            var maxYear = d.getFullYear() + 1;
            for (var y = minYear; y <= maxYear; y++) {
                yearsList.push(y);
            }
            yearsList.forEach(function (year) {
                holidays = holidays.concat(DateOperationUtil.JoursFeries(year));
            });
            var holidaysDates = [];
            holidays.forEach(function (hDate) {
                holidaysDates.push(hDate.toDateString());
            });
            return day !== 0 && day !== 6 && holidaysDates.indexOf(d.toDateString()) === -1;
        };
        this.andriceOffersSubject = new Subject();
        this.andriceOffersObservable = this.andriceOffersSubject.asObservable();
        this.andriceOffersSelectedSubject = new Subject();
        this.andriceOffersSelected = this.andriceOffersSelectedSubject.asObservable();
        this.idCreatedAndriceOfferSubject = new Subject();
        this.idCreatedAndriceOffer = this.idCreatedAndriceOfferSubject.asObservable();
        this.workingDaysSubject = new Subject();
        this.workingDays = this.workingDaysSubject.asObservable();
        this.lotsSubject = new Subject();
        this.lots = this.lotsSubject.asObservable();
        this.jobsSubject = new Subject();
        this.jobs = this.jobsSubject.asObservable();
    }
    AndriceOfferService.prototype.getIdFromLocation = function (location) {
        var str = location.toString();
        str = str.split('/');
        this.idCreatedObject = str[str.length - 1];
    };
    AndriceOfferService.prototype.createAndriceOffer = function (andriceOffer) {
        var _this = this;
        return Observable.create(function (observer) {
            _this.appService
                .postResource(_this.andriceOfferUrl + 'create', andriceOffer)
                .subscribe(function (response) {
                console.log('POST request successful', response);
                _this.getIdFromLocation(response.headers.getAll('location'));
                var report = new Reporting(new Date(), 'CREATION OA', _this.authService.user.trigram, _this.authService.user.idEmployee, 'AndriceOffer', andriceOffer.idClient);
                _this.reportingService.createReport(report);
                observer.next(_this.idCreatedObject);
                _this.toastr.success('Offre Andrice créé avec succès !', '', {
                    timeOut: 2000
                });
            }, function (error) {
                _this.toastr.error('Erreur lors de la création de l\'offre !', '<mat-icon>info</mat-icon>', { timeOut: 2000 });
                console.log('POST error : ', error);
            }, function () {
                observer.complete();
            });
        });
    };
    AndriceOfferService.prototype.createJob = function (job) {
        var _this = this;
        this.appService.postResource(this.jobUrl + 'create', job).subscribe(function (response) {
            console.log('POST request successful', response);
        }, function (error) {
            _this.toastr.error('Erreur lors de la sauvegarde des travaux !', '', { timeOut: 2000 });
            console.log('POST error : ', error);
        });
    };
    AndriceOfferService.prototype.createLot = function (lot) {
        var _this = this;
        return Observable.create(function (observer) {
            _this.appService.postResource(_this.lotUrl + 'create', lot).subscribe(function (response) {
                console.log('POST request successful', response);
                _this.getIdFromLocation(response.headers.getAll('location'));
                observer.next(_this.idCreatedObject);
            }, function (error) {
                _this.toastr.error('Erreur lors de la sauvegarde des lots !', '', { timeOut: 2000 });
                console.log('POST error : ', error);
            }, function () {
                observer.complete();
            });
        }).pipe(first());
    };
    AndriceOfferService.prototype.createWorkingDay = function (workingDay) {
        var _this = this;
        this.appService
            .postResource(this.workingDaysUrl + 'create', workingDay)
            .subscribe(function (response) {
            console.log('POST request successful', response);
            _this.getIdFromLocation(response.headers.getAll('location'));
        }, function (error) {
            _this.toastr.error('Erreur lors de la sauvegarde des jours ouvrés !', '', { timeOut: 2000 });
            console.log('POST error : ', error);
        });
    };
    AndriceOfferService.prototype.getAndriceOffers = function () {
        var _this = this;
        this.appService.getResource(this.andriceOfferUrl).subscribe(function (response) {
            console.log(response);
            _this.andriceOffersSubject.next(response);
        }, function (error) {
            console.log('GET error : ' + error);
        });
        return this.andriceOffersObservable.pipe(first());
    };
    AndriceOfferService.prototype.getAndriceOffersByClientId = function (id) {
        var _this = this;
        this.appService
            .getResource(this.andriceOfferUrl + 'client/' + id)
            .subscribe(function (response) {
            console.log(response);
            _this.andriceOffersSubject.next(response);
        }, function (error) {
            console.log('GET error : ' + error);
        });
        return this.andriceOffersObservable.pipe(first());
    };
    AndriceOfferService.prototype.getAndriceOfferById = function (id) {
        var _this = this;
        return new Observable(function (observer) {
            _this.appService.getResource(_this.andriceOfferUrl + id).subscribe(function (response) {
                console.log('GET request successful', response);
                _this.andriceOffersSelectedSubject.next(response);
                observer.next(response);
            }, function (error) {
                _this.toastr.error('Cet offre Andrice n\'existe plus !', '', {
                    timeOut: 2000
                });
                console.log('GET error : ', error);
            }, function () { observer.complete(); });
        }).pipe(first());
    };
    AndriceOfferService.prototype.getJobsByIdAo = function (id) {
        var _this = this;
        this.appService.getResource(this.jobUrl + 'andriceoffers/' + id).subscribe(function (response) {
            console.log('GET request successful', response);
            _this.jobsSubject.next(response);
        }, function (error) {
            console.log('GET error : ', error);
        });
        return this.jobs.pipe(first());
    };
    AndriceOfferService.prototype.getLotByIdAo = function (id) {
        var _this = this;
        this.appService.getResource(this.lotUrl + 'andriceoffers/' + id).subscribe(function (response) {
            console.log('GET request successful', response);
            _this.lotsSubject.next(response);
        }, function (error) {
            console.log('GET error : ', error);
        });
        return this.lots.pipe(first());
    };
    AndriceOfferService.prototype.getWorkingDaysByIdLot = function (id) {
        var _this = this;
        return new Observable(function (observer) {
            _this.appService.getResource(_this.workingDaysUrl + 'lot/' + id).subscribe(function (response) {
                console.log('GET request successful', response);
                _this.workingDaysSubject.next(response);
                observer.next(response);
            }, function (error) {
                console.log('GET error : ', error);
            }, function () { observer.complete(); });
        }).pipe(first());
    };
    AndriceOfferService.prototype.updateAndriceOffer = function (andriceOffer) {
        var _this = this;
        return new Observable(function (observer) {
            _this.appService.putResource(_this.andriceOfferUrl + andriceOffer.id, andriceOffer)
                .subscribe(function (response) {
                var report = new Reporting(new Date(), 'MISE A JOUR OA', _this.authService.user.trigram, _this.authService.user.idEmployee, 'AndriceOffer', andriceOffer.idClient);
                _this.reportingService.createReport(report);
                console.log('PUT request successful', response);
                _this.toastr.info('Offre Andrice mise à jour avec succès !', '', {
                    timeOut: 2000
                });
                _this.idCreatedAndriceOfferSubject.next(andriceOffer.id);
                observer.next(andriceOffer.id);
            }, function (error) {
                console.log('GET error : ', error);
                _this.toastr.error('Erreur lors de la mise à jour de l\'offre Andrice !', '', { timeOut: 2000 });
            }, function () {
                observer.complete();
            });
        }).pipe(first());
    };
    AndriceOfferService.prototype.updateJob = function (job) {
        var _this = this;
        this.appService.putResource(this.jobUrl + job.id, job).subscribe(function (response) {
            console.log('PUT request successful', response);
            _this.idCreatedAndriceOfferSubject.next(job.idAo);
        }, function (error) {
            console.log('GET error : ', error);
            _this.toastr.error('Erreur lors de la mise à jour des travaux', '', {
                timeOut: 2000
            });
        });
        return this.idCreatedAndriceOffer.pipe(first());
    };
    AndriceOfferService.prototype.updateLot = function (lot) {
        var _this = this;
        this.appService.putResource(this.lotUrl + lot.id, lot).subscribe(function (response) {
            console.log('PUT request successful', response);
            _this.idCreatedAndriceOfferSubject.next(lot.idAo);
        }, function (error) {
            console.log('GET error : ', error);
            _this.toastr.error('Erreur lors de la mise à jour des lots', '', {
                timeOut: 2000
            });
        });
        return this.idCreatedAndriceOffer.pipe(first());
    };
    AndriceOfferService.prototype.updateWorkingDay = function (workingday) {
        var _this = this;
        this.appService
            .putResource(this.workingDaysUrl + workingday.id, workingday)
            .subscribe(function (response) {
            console.log('PUT request successful', response);
        }, function (error) {
            console.log('GET error : ', error);
            _this.toastr.error('Erreur lors de la mise à jour des jours ouvrés', '', { timeOut: 2000 });
        });
    };
    AndriceOfferService.prototype.deleteAndriceOfferById = function (id) {
        var _this = this;
        return this.appService.deleteResource(this.andriceOfferUrl + id).subscribe(function (response) {
            // const report = new Reporting(new Date(), 'SUPPRESSION OA', this.authService.user.trigram, this.authService.user.idEmployee, 'AndriceOffer', id);
            // this.reportingService.createReport(report);
            console.log('DELETE request successful ', response);
            _this.andriceOffersSelectedSubject.next();
            _this.toastr.info('L\'offre Andrice a été supprimée !', '', {
                timeOut: 2000
            });
        }, function (error) {
            console.log('DELETE error : ', error);
            _this.toastr.error('Erreur lors de la suppression de l\'offre Andrice !', '', { timeOut: 2000 });
        });
    };
    AndriceOfferService.prototype.deleteLotById = function (id) {
        var _this = this;
        return this.appService.deleteResource(this.lotUrl + id).subscribe(function (response) {
            console.log('DELETE request successful ', response);
            _this.andriceOffersSelectedSubject.next();
        }, function (error) {
            console.log('DELETE error : ', error);
            _this.toastr.error('Erreur lors de la suppression du lot !', '', {
                timeOut: 2000
            });
        });
    };
    AndriceOfferService.prototype.deleteJobById = function (id) {
        var _this = this;
        return this.appService.deleteResource(this.jobUrl + id).subscribe(function (response) {
            console.log('DELETE request successful ', response);
            _this.andriceOffersSelectedSubject.next();
        }, function (error) {
            console.log('DELETE error : ', error);
            _this.toastr.error('Erreur lors de la suppression des travaux !', '', {
                timeOut: 2000
            });
        });
    };
    AndriceOfferService.prototype.deleteWorkingDayById = function (id) {
        var _this = this;
        return this.appService.deleteResource(this.workingDaysUrl + id).subscribe(function (response) {
            console.log('DELETE request successful ', response);
            _this.andriceOffersSelectedSubject.next();
        }, function (error) {
            console.log('DELETE error : ', error);
            _this.toastr.error('Erreur lors de la suppression du jour ouvré !', '', {
                timeOut: 2000
            });
        });
    };
    AndriceOfferService.prototype.createAndriceOfferLogicModel = function (andriceOfferContract) {
        var _this = this;
        return Observable.create(function (observer) {
            _this.appService
                .postResource(_this.andriceOfferUrl + 'generateAO', andriceOfferContract)
                .subscribe(function (response) {
                console.log('POST request successful', response);
                console.log(andriceOfferContract);
                _this.getIdFromLocation(response.headers.getAll('location'));
                observer.next(_this.idCreatedObject);
            }, function (error) {
                console.log('POST error : ', error);
            }, function () {
                observer.complete();
            });
        }).pipe(first());
    };
    AndriceOfferService.prototype.getPDF = function (path) {
        return this.appService.getResourcePdf(environment.baseUrlAndriceOffer + '/pdf/' + path);
    };
    AndriceOfferService.prototype.showPDF = function (path) {
        console.log('path ', path);
        this.getPDF(path).subscribe(function (x) {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([x], { type: 'application/pdf' });
            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }
            var fileURL = window.URL.createObjectURL(newBlob);
            window.open(fileURL, '_blank');
        });
    };
    AndriceOfferService.prototype.getAndriceOfferMaxVersion = function (nbAo) {
        var _this = this;
        this.appService
            .getResource(this.andriceOfferUrl + 'getMaxVersion/' + nbAo)
            .subscribe(function (response) {
            console.log('GET request successful', response);
            _this.andriceOffersSelectedSubject.next(response);
        }, function (error) {
            console.log('GET error : ' + error);
        });
        return this.andriceOffersSelected;
    };
    AndriceOfferService.prototype.uploadAndriceOffer = function (data, fileName) {
        var _this = this;
        this.appService.uploadResource(environment.baseUrlAndriceOffer + '/upload', data, fileName).subscribe(function (response) {
            _this.toastr.success('Offre andrice importée avec succès !', '', {
                timeOut: 2000
            });
        }, function (error) {
            _this.toastr.error('Erreur lors de l\'import de l\'offre andrice !', '', {
                timeOut: 2000
            });
        });
    };
    AndriceOfferService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AndriceOfferService_Factory() { return new AndriceOfferService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AppService), i0.ɵɵinject(i3.ToastrService), i0.ɵɵinject(i4.AuthService), i0.ɵɵinject(i5.ReportingService)); }, token: AndriceOfferService, providedIn: "root" });
    return AndriceOfferService;
}());
export { AndriceOfferService };
