<div class="content-container">
    <mat-icon id="close-icon" (click)="confirmDialog()">close</mat-icon>
    <span class="content-span toast-bottom-full-width" [innerHTML]="data.message"></span>
</div>
<div>
    <button mat-raised-button id="no-button" [mat-dialog-close]="false"><mat-icon>cancel</mat-icon>{{cancelButtonText}}</button>
    <!--button mat-raised-button id="info-button" (click) ="openDocument()"><mat-icon>remove_red_eye</mat-icon><span id="test">{{seeActual}}</span></button-->
    <button mat-raised-button id="yes-button" [mat-dialog-close]="true"><mat-icon>done</mat-icon>{{confirmButtonText}}</button>
</div>

