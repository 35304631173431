import {WorkingDay} from './working-day.model';

export class Lot {
  constructor(

    public idAo?: number,
    public mdr?: number,
    public endDate?: Date,
    public startDate?: Date,
    public lotNumber?: number,
    public id?: number,
    public workingDaysList?: WorkingDay[],
    public category?: string,
    public ordre?: number,
    public nbWorkingDays?: number,
    public type?: string,

) {}

}
