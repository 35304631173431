import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '../../../shared/app.service';
import { AdminService } from '../shared/admin.service';
import { UserRoleModel } from '../shared/userRole.model';
import { forkJoin } from 'rxjs';
import { EmployeeService } from '../../employees/shared/employee.service';
import { first, map, startWith } from 'rxjs/operators';
import { DataService } from 'src/app/shared/data.service';
var AddUserComponent = /** @class */ (function () {
    function AddUserComponent(formBuilder, router, appService, adminService, employeeService, dataService) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.appService = appService;
        this.adminService = adminService;
        this.employeeService = employeeService;
        this.dataService = dataService;
        this.newEmployeeList = [];
        this.employeeFilter = new FormControl('');
    }
    AddUserComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.addUserForm = this.formBuilder.group({
            username: ['', [Validators.required]],
            trigram: ['', [Validators.required]],
            password: ['', [Validators.required, Validators.minLength(6),
                    Validators.maxLength(20)]],
            role: ['', [Validators.required]],
            employee: ['', [Validators.required]],
            confirmPassword: ['']
        }, { validator: this.checkPasswords });
        this.dataService.getUserRoles().subscribe(function (userRoles) {
            _this.userRoles = userRoles.userRoles.filter(function (r) { return r.name !== 'ROLE_PRESIDENT'; });
        });
        //
        // Get employees and users to filter employees and display those who doesn't have user account
        //
        this.subscription = forkJoin([
            this.employeeService.getEmployees().pipe(first()),
            this.adminService.getUsers().pipe(first())
        ]).subscribe(function (_a) {
            var employees = _a[0], users = _a[1];
            _this.employees = employees;
            _this.users = users;
            _this.employees.forEach(function (employee) {
                var userfound = _this.users.find(function (user) { return user.idEmployee === employee.id; });
                if (userfound === undefined && (employee.family === 'Siège' || employee.family === 'Business')) {
                    _this.newEmployeeList.push(employee);
                }
            });
            _this.newEmployeeList.sort(function (a, b) {
                return a.firstName > b.firstName ? 1 : -1;
            });
            _this.filteredEmployees = _this.employeeFilter.valueChanges
                .pipe(startWith(''), map(function (value) { return _this.appService.employeesFilter(_this.newEmployeeList, value); }));
            _this.adminService.resourcesLoaded = true;
        });
    };
    Object.defineProperty(AddUserComponent.prototype, "password", {
        get: function () {
            return this.addUserForm.get('password').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddUserComponent.prototype, "confirmPassword", {
        get: function () {
            return this.addUserForm.get('confirmPassword').value;
        },
        enumerable: true,
        configurable: true
    });
    /* Called on each input in either password field */
    // here we have the 'passwords' group
    AddUserComponent.prototype.checkPasswords = function (group) {
        var pass = group.controls.password.value;
        var confirmPass = group.controls.confirmPassword.value;
        return pass === confirmPass ? null : { notSame: true };
    };
    AddUserComponent.prototype.reseatEmployeeSearchField = function () {
        var _this = this;
        this.employeeFilter = new FormControl('');
        this.filteredEmployees = this.employeeFilter.valueChanges
            .pipe(startWith(''), map(function (value) { return _this.appService.employeesFilter(_this.newEmployeeList, value); }));
    };
    AddUserComponent.prototype.selectEmployee = function (employee) {
        this.addUserForm.get('username').setValue(employee.workEmail);
        this.addUserForm.get('trigram').setValue(employee.trigram);
        this.addUserForm.get('password').setValue(employee.trigram + '20andrice');
        this.addUserForm.get('confirmPassword').setValue(employee.trigram + '20andrice');
    };
    AddUserComponent.prototype.onSubmitForm = function () {
        var _this = this;
        // Create user and redirect to users list
        var user = Object.assign({}, this.addUserForm.getRawValue());
        user.role = new UserRoleModel(this.addUserForm.get('role').value.id, this.addUserForm.get('role').value.name);
        user.accountLocked = false;
        user.enable = true;
        var employeeId = Number(this.addUserForm.get('employee').value);
        user.idEmployee = employeeId;
        this.adminService.createUser(user).subscribe(function () {
            _this.router.navigate(['/users']);
        });
    };
    AddUserComponent.prototype.cancel = function () {
        this.router.navigate(['/users']);
    };
    AddUserComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return AddUserComponent;
}());
export { AddUserComponent };
