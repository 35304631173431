import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {DirUppercaseDirective} from './directives/dir-uppercase.directive';
import {DirOnlyFloatDirective} from './directives/dir-onlyfloat.directive';
import {DirTitlecaseDirective} from './directives/dir-titlecase.directive';
import {DirNormalizeDirective} from './directives/dir-normalize.directive';
import {DirPhonenumberDirective} from './directives/dir-phonenumber.directive';
import {DirChangedateDirective} from './directives/dir-changedate.directive';
import {ConfirmDialogComponent} from './dialogs/confirm-dialog/confirm-dialog.component';

import {MaterialModule} from './material.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {DialogService} from './dialog.service';
import {TjmInfoDialogComponent} from './dialogs/tjm-info-dialog/tjm-info-dialog.component';
import {TjmLotDialogComponent} from './dialogs/tjm-lot-dialog/tjm-lot-dialog.component';
import {ReactiveFormsModule} from '@angular/forms';
import {NavbarComponent} from '../core/navbar/navbar.component';
import {RouterModule} from '@angular/router';
import {InfoDialogComponent} from './dialogs/info-dialog/info-dialog.component';
import {DirSirenNumberDirective} from './directives/dir-siren-number.directive';
import {DirNoWhiteSpaceAndSpecialCharactersDirective} from './directives/dir-no-white-space-and-special-characters.directive';
import {NgxSpinnerModule} from 'ngx-spinner';
import {DirPreventCopyCutPasteDirective} from './directives/dir-prevent-copy-cut-paste.directive';
import {FrenchPaginatorIntl} from './utils/french-paginator-intl';
import { DirCapitaliseCountryDirective } from './directives/dir-capitalise-country.directive';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { UploadDialogComponent } from './dialogs/upload-dialog/upload-dialog.component';
import { LockedDialogComponent } from './dialogs/locked-dialog/locked-dialog.component';

@NgModule({
  declarations: [
    DirUppercaseDirective,
    DirOnlyFloatDirective,
    DirCapitaliseCountryDirective,
    DirTitlecaseDirective,
    DirNormalizeDirective,
    DirSirenNumberDirective,
    DirPhonenumberDirective,
    DirChangedateDirective,
    DirNoWhiteSpaceAndSpecialCharactersDirective,
    ConfirmDialogComponent,
    TjmInfoDialogComponent,
    TjmLotDialogComponent,
    UploadDialogComponent,
    LockedDialogComponent,
    NavbarComponent,
    InfoDialogComponent,
    DirPreventCopyCutPasteDirective
  ],
  exports: [
    DirUppercaseDirective,
    DirOnlyFloatDirective,
    DirTitlecaseDirective,
    DirCapitaliseCountryDirective,
    DirNormalizeDirective,
    DirSirenNumberDirective,
    DirPreventCopyCutPasteDirective,
    DirPhonenumberDirective,
    DirChangedateDirective,
    DirNoWhiteSpaceAndSpecialCharactersDirective,
    MaterialModule,
    NavbarComponent,
    NgxSpinnerModule
  ],
  entryComponents: [
    ConfirmDialogComponent,
    TjmInfoDialogComponent,
    TjmLotDialogComponent,
    InfoDialogComponent,
    UploadDialogComponent,
    LockedDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgbModule,
  ],
  providers: [
    DialogService,
    DatePipe,
  ]
})
export class SharedModule { }
