
<div>
  <div class="content-container" *ngIf="lot != null">
    <mat-icon id="close-icon" (click)="cancel()">close</mat-icon>
    <span class="content-span toast-bottom-full-width">
      <form [formGroup]="tjmForm" >
      <h1 mat-dialog-title>Nombre de jours ouvrés du lot</h1>
      <mat-dialog-content>
        <div class="row">
          <table class="table table-hover details">
                <thead>
                <tr>
                  <th>Mois</th>
                  <th>Jours ouvrés ({{moneyType}})</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let workingday of workingDays; let i = index">
                  <td>{{monthAndYear[i]}}</td>
                  <td>
                    <mat-form-field>
                      <input matInput formControlName="nbDays" [errorStateMatcher]="matcher"  placeholder="Jours ouvrés"  [value]="workingday.nbDays" (change)="updateWorkingDay($event,i)" onkeydown="javascript: return event.keyCode == 69 ? false : true" type="number" appDirPreventCopyCutPaste>
                    </mat-form-field>

                  </td>
                  <td></td>
                </tr>

                </tbody>
              </table>

        </div>
      </mat-dialog-content>
      <mat-dialog-actions>
          <button mat-raised-button id="no-button" (click)="cancel()"><mat-icon>cancel</mat-icon>  Annuler</button>
          <button mat-raised-button id="yes-button" (click)="confirmDialog()"><mat-icon>done</mat-icon>  Valider</button>
      </mat-dialog-actions>
    </form>
    </span>
  </div>
</div>

<!--  <button mat-raised-button id="no-button" [mat-dialog-close]="false"><mat-icon>cancel</mat-icon>  Annuler</button>-->
<!--  <button mat-raised-button id="yes-button" [mat-dialog-close]="true"><mat-icon>done</mat-icon>  Valider</button>-->

