import * as tslib_1 from "tslib";
import { MatPaginatorIntl } from '@angular/material';
import * as i0 from "@angular/core";
var FrenchPaginatorIntl = /** @class */ (function (_super) {
    tslib_1.__extends(FrenchPaginatorIntl, _super);
    function FrenchPaginatorIntl() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.itemsPerPageLabel = '';
        _this.nextPageLabel = 'suivant';
        _this.previousPageLabel = 'précédent';
        _this.getRangeLabel = function (page, pageSize, length) {
            if (length === 0 || pageSize === 0) {
                return '0 od ' + length;
            }
            length = Math.max(length, 0);
            var startIndex = page * pageSize;
            // If the start index exceeds the list length, do not try and fix the end index to the end.
            var endIndex = startIndex < length ?
                Math.min(startIndex + pageSize, length) :
                startIndex + pageSize;
            return startIndex + 1 + ' - ' + endIndex + ' / ' + length;
        };
        return _this;
    }
    FrenchPaginatorIntl.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FrenchPaginatorIntl_Factory() { return new FrenchPaginatorIntl(); }, token: FrenchPaginatorIntl, providedIn: "root" });
    return FrenchPaginatorIntl;
}(MatPaginatorIntl));
export { FrenchPaginatorIntl };
