import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmployeeService } from '../../employees/shared/employee.service';
import { Employee } from '../../employees/shared/employee.model';
import { ToastrService } from 'ngx-toastr';
import { flatMap, catchError } from 'rxjs/operators';
import { AdminService } from '../../admin/shared/admin.service';
import { UserToPersistModel } from '../../admin/shared/userToPersist.model';
import { User } from '../../admin/shared/user.model';
import { UserRoleModel } from '../../admin/shared/userRole.model';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  resetForm: FormGroup;
  userRoles: UserRoleModel[];


  constructor(
    private toastr: ToastrService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.resetForm = new FormGroup({
      email: new FormControl('', [Validators.required])
    });
  }

  generateRandomPassword(lengthOfCode: number) {
    let password = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;\'[]\=-)(*&%$#@!';
    for (let i = 0; i < lengthOfCode; i++) {
      password += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return password;
  }

  ResetPassword() {
    const email = this.resetForm.value.email;
    const password = this.generateRandomPassword(8);
    let employee: Employee;
    let user: User;
    this.initForm();
    this.authService.getEmployeeByWorkMailForgotPassword(email).pipe(
      flatMap((empl) => {employee = empl ; return this.authService.getUserById(employee.id); }),
      flatMap((u: User) => { user = u; return this.authService.sendNewPassword(employee.id, password); }, ),
        flatMap((obj) => {
          const us = Object.assign(user, {password});
          const data = {
            employee: JSON.stringify(employee),
            user: JSON.stringify(us),
          };
          return this.authService.sendForgotPasswordEmail(data);
        })
  ).subscribe((res3) => {
      this.toastr.success('Un mail vous a été envoyé afin de réinitialiser votre mot de passe', 'Succès');
  }, 
  (err)=> {
    this.toastr.error('Aucun compte n\'est associé à cette adresse email', 'Compte non trouvé!');
  });
  }

}
