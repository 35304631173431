import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Lot} from '../../../modules/andrice-offers/shared/lot.model';
import {DatePipe} from '@angular/common';
import {AndriceOfferService} from '../../../modules/andrice-offers/shared/andrice-offer.service';
import {WorkingDay} from '../../../modules/andrice-offers/shared/working-day.model';
import {DateOperationUtil} from '../../utils/date-utils';

@Component({
  selector: 'app-tjm-info-dialog',
  templateUrl: './tjm-info-dialog.component.html',
  styleUrls: ['./tjm-info-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TjmInfoDialogComponent implements OnInit {

  tjmForm: FormGroup;
  workingDays: Array<WorkingDay> = new Array<WorkingDay>();
  matcher: any;
  lot: Lot ;
  monthAndYear: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<TjmInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private andriceOfferService: AndriceOfferService) { }

  ngOnInit() {
    this.lot = new Lot();
    this.lot = this.data.message;
    console.log(this.lot);
    let newWorkingday: WorkingDay;
    // recuperation de la liste des workingDays qqu'on ajoute a notre liste
    for (const workingday of this.lot.workingDaysList) {
      newWorkingday = new WorkingDay();
      newWorkingday.nbDays = workingday.nbDays;
      newWorkingday.idLot = null;
      newWorkingday.amount = workingday.amount;
      newWorkingday.ordre = workingday.ordre;
      newWorkingday.id = null;
      newWorkingday.month = workingday.month;
      this.workingDays.push(newWorkingday);
    }
    console.log(this.workingDays);
    // sinon on recupere les mois de ce lot pour l'affichage
    this.getAllMonth(this.lot.startDate, this.lot.endDate);
    this.tjmForm = this.formBuilder.group({
      nbDays: new FormControl('')
    });

    this.tjmForm.get('nbDays').disable();

  }


  close() {
    this.dialogRef.close();

  }

  getAllMonth(firstDate: Date, secondDate: Date) {

    // recuperation de la liste des mois entre une intervalle de date
    const months: number[] = [];
    const nbMonth = this.lot.workingDaysList.length;
    console.log('nb Mois :', nbMonth);
    let date = firstDate;
    for (let i = 0; i <= nbMonth; i++) {
      months.push(date.getMonth());
      this.getMonthAndYear(date);
      date = DateOperationUtil.getFirstDayOfNextMonth(date);
    }
    console.log(this.monthAndYear);
    console.log(months);
    return months;

  }


  /*
  retourne une lite comprenant les ois et les annees correspondant
  */
  getMonthAndYear(date: Date) {

    const month = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    const result: string = month[date.getMonth()] + ' ' + date.getFullYear();
    this.monthAndYear.push(result);
  }




}
