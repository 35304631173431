import { WorkingDay } from '../../modules/andrice-offers/shared/working-day.model';
import * as i0 from "@angular/core";
var DateOperationUtil = /** @class */ (function () {
    function DateOperationUtil() {
    }
    DateOperationUtil.numberWorkingDayYear = function (an) {
        var begin = new Date(an, 0, 1);
        var end = new Date(an, 11, 31);
        return this.getWorkingDays(begin, end);
    };
    DateOperationUtil.getWorkingDays = function (startDate, endDate) {
        var result = 0;
        var nbJoursFeries = 0;
        var currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            var weekDay = currentDate.getDay();
            if (weekDay !== 0 && weekDay !== 6) {
                result++;
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
        if (startDate.getFullYear() === endDate.getFullYear()) {
            nbJoursFeries = this.nbOffDays(startDate, endDate);
        }
        else {
            var startDateYear = startDate.getFullYear();
            var endDateYear = endDate.getFullYear();
            while (startDateYear <= endDateYear) {
                if (startDateYear < endDateYear) {
                    nbJoursFeries += this.nbOffDays(startDate, new Date(startDateYear, 12, 31));
                    startDate = new Date(startDateYear + 1, 0, 1);
                    startDateYear += 1;
                }
                else {
                    nbJoursFeries += this.nbOffDays(startDate, endDate);
                    startDateYear += 1;
                }
            }
        }
        result -= nbJoursFeries;
        return result;
    };
    DateOperationUtil.JoursFeries = function (an) {
        var JourAn = new Date(an, 0, 1);
        var FeteTravail = new Date(an, 4, 1);
        var Victoire1945 = new Date(an, 4, 8);
        var FeteNationale = new Date(an, 6, 14);
        var Assomption = new Date(an, 7, 15);
        var Toussaint = new Date(an, 10, 1);
        var Armistice = new Date(an, 10, 11);
        var Noel = new Date(an, 11, 25);
        // const SaintEtienne = new Date(an, 11, 26);
        var G = an % 19;
        var C = Math.floor(an / 100);
        var H = (C - Math.floor(C / 4) - Math.floor((8 * C + 13) / 25) + 19 * G + 15) % 30;
        var I = H - Math.floor(H / 28) * (1 - Math.floor(H / 28) * Math.floor(29 / (H + 1)) * Math.floor((21 - G) / 11));
        var J = (an * 1 + Math.floor(an / 4) + I + 2 - C + Math.floor(C / 4)) % 7;
        var L = I - J;
        var MoisPaques = 3 + Math.floor((L + 40) / 44);
        var JourPaques = L + 28 - 31 * Math.floor(MoisPaques / 4);
        var LundiPaques = new Date(an, MoisPaques - 1, JourPaques + 1);
        var Ascension = new Date(an, MoisPaques - 1, JourPaques + 39);
        // const LundiPentecote = new Date(an, MoisPaques - 1, JourPaques + 50);
        var TabOffDay = [JourAn, LundiPaques, FeteTravail, Victoire1945, Ascension,
            FeteNationale, Assomption, Toussaint, Armistice, Noel];
        return TabOffDay;
    };
    DateOperationUtil.nbOffDays = function (startDate, endDate) {
        var NbOffDays = 0;
        var TabOffDay = this.JoursFeries(startDate.getFullYear());
        TabOffDay.forEach(function (day) {
            // si le jour ferie n'est pas un samedi ou un dimanche
            if (day.getDay() !== 6 && day.getDay() !== 0) {
                // on test que le jour férié est compris entre startDate et endDate
                if (day >= startDate && day <= endDate) {
                    NbOffDays++;
                }
            }
        });
        return NbOffDays;
    };
    DateOperationUtil.getFirstDayOfNextMonth = function (date) {
        // retourne le premier jour du mois suivant
        if (date.getMonth() === 11) {
            return new Date(date.getFullYear() + 1, 0, 1);
        }
        else {
            return new Date(date.getFullYear(), date.getMonth() + 1, 1);
        }
    };
    DateOperationUtil.getEndDate = function (startingDate, endingDate) {
        if (startingDate.getMonth() !== endingDate.getMonth()
            || ((startingDate.getFullYear() !== endingDate.getFullYear()) && startingDate.getMonth() === endingDate.getMonth())) {
            return new Date(startingDate.getFullYear(), startingDate.getMonth() + 1, 0, 0, 0, 0);
        }
        else {
            return endingDate;
        }
    };
    DateOperationUtil.getAllMonth = function (firstDate, secondDate) {
        // recuperation de la liste des mois entre une intervalle de date
        var months = [];
        var nbMonth = 0;
        if (firstDate.getFullYear() === secondDate.getFullYear()) {
            // si on a la meme annee pour la date de debut et la date de fin, le nombre de
            // mois sera la difference entre le mois de la date de fin et celui de la date de debut
            nbMonth = secondDate.getMonth() - firstDate.getMonth();
        }
        else {
            // si nous avons des annees differentes alors on recupere les 2 annees
            // et tant que i est != de la deuxieme annee on fait la difference entre le 12mois et le mois de la premiere date et
            // notre nouvelle date passe au premier jour de l'annee suivante soit le 1 janvier
            // qi i est === a l'annee de la seconde date alors on recupere juste le mois de la seconde anee +1 qu'on ajoute a notre nombre de mois
            // nbMonth = Math.abs(Math.round(diff));
            var firstYear = Number(firstDate.getFullYear());
            var usedFirstDay = firstDate;
            var secondYear = Number(secondDate.getFullYear());
            for (var i = firstYear; i <= secondYear; i++) {
                if (i !== secondYear) {
                    nbMonth += 11 - usedFirstDay.getMonth();
                    usedFirstDay = new Date(i + 1, 1, 1);
                }
                else {
                    nbMonth += secondDate.getMonth() + 1;
                }
            }
        }
        var date = firstDate;
        for (var i = 0; i <= nbMonth; i++) {
            months.push(date.getMonth());
            date = this.getFirstDayOfNextMonth(date);
        }
        return months;
    };
    DateOperationUtil.getWorkingDayJo = function (lot) {
        // get the stating Date and the ending date of the lot
        var workingDays = [];
        var firstDate = new Date(lot.startDate.getFullYear(), lot.startDate.getMonth(), lot.startDate.getDate(), 0, 0, 0);
        var secondDate = new Date(lot.endDate.getFullYear(), lot.endDate.getMonth(), lot.endDate.getDate(), 0, 0, 0);
        // getting the number of the months betweens the first and the second date
        var months = this.getAllMonth(firstDate, secondDate);
        // tslint:disable-next-line:label-position prefer-const
        var workingDay;
        var firstDayNextMonth = null;
        // for each month
        for (var i = 0; i < months.length; i++) {
            // create a working day object and calculate the number of workingDays for each month
            workingDay = new WorkingDay();
            workingDay.month = months[i] + 1;
            workingDay.amount = lot.mdr;
            workingDay.idLot = lot.id;
            workingDay.id = null;
            if (i === 0) {
                workingDay.nbDays = this.getWorkingDays(firstDate, this.getEndDate(firstDate, secondDate));
                firstDayNextMonth = this.getFirstDayOfNextMonth(firstDate);
            }
            else {
                workingDay.nbDays = this.getWorkingDays(firstDayNextMonth, this.getEndDate(firstDayNextMonth, secondDate));
                firstDayNextMonth = this.getFirstDayOfNextMonth(this.getEndDate(firstDayNextMonth, secondDate));
            }
            workingDays.push(workingDay);
        }
        return workingDays;
    };
    DateOperationUtil.formatDate = function (date) {
        return this.datePipe.transform(date, 'short');
    };
    DateOperationUtil.day_of_the_month = function (d) {
        // function to filter dates disable weekend, french holidays and previous dates in the datepicker
        return (d.getDate() < 10 ? '0' : '') + d.getDate();
    };
    DateOperationUtil.month_of_the_year = function (d) {
        var month = d.getMonth() + 1;
        return (month < 10 ? '0' : '') + month;
    };
    // function to filter dates disable weekend, french holidays and previous dates in the datepicker
    DateOperationUtil.signatureDateFilter = function (d) {
        var day = d.getDay();
        var holidays = [];
        var yearsList = [];
        var minYear = d.getFullYear() - 1;
        var maxYear = d.getFullYear() + 1;
        for (var y = minYear; y <= maxYear; y++) {
            yearsList.push(y);
        }
        yearsList.forEach(function (year) {
            holidays = holidays.concat(DateOperationUtil.JoursFeries(year));
        });
        var holidaysDates = [];
        holidays.forEach(function (hDate) {
            holidaysDates.push(hDate.toDateString());
        });
        return day !== 0 && day !== 6 && holidaysDates.indexOf(d.toDateString()) === -1 && d > new Date();
    };
    // function to filter dates disable weekend, french holidays and previous month in the datepicker
    DateOperationUtil.odmEmployeeDateFilter = function (d) {
        var day = d.getDay();
        var startMonth = new Date();
        var holidays = [];
        var yearsList = [];
        var minYear = d.getFullYear() - 1;
        var maxYear = d.getFullYear() + 1;
        for (var y = minYear; y <= maxYear; y++) {
            yearsList.push(y);
        }
        yearsList.forEach(function (year) {
            holidays = holidays.concat(DateOperationUtil.JoursFeries(year));
        });
        var holidaysDates = [];
        holidays.forEach(function (hDate) {
            holidaysDates.push(hDate.toDateString());
        });
        // set the date on the first day to the current month
        startMonth.setDate(1);
        return day !== 0 && day !== 6 && holidaysDates.indexOf(d.toDateString()) === -1 && d > startMonth;
    };
    DateOperationUtil.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DateOperationUtil_Factory() { return new DateOperationUtil(); }, token: DateOperationUtil, providedIn: "root" });
    return DateOperationUtil;
}());
export { DateOperationUtil };
