import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { InfoDialogComponent } from './dialogs/info-dialog/info-dialog.component';
import { TjmLotDialogComponent } from './dialogs/tjm-lot-dialog/tjm-lot-dialog.component';
import { TjmInfoDialogComponent } from './dialogs/tjm-info-dialog/tjm-info-dialog.component';
import { UploadDialogComponent } from './dialogs/upload-dialog/upload-dialog.component';
import { LockedDialogComponent } from './dialogs/locked-dialog/locked-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var DialogService = /** @class */ (function () {
    function DialogService(dialog) {
        this.dialog = dialog;
    }
    DialogService.prototype.openConfirmDialog = function (msg) {
        return this.dialog.open(ConfirmDialogComponent, {
            width: '50%',
            panelClass: 'dialog-container',
            disableClose: true,
            data: {
                message: msg
            }
        });
    };
    DialogService.prototype.openTjmLotDialog = function (lot, moneyType) {
        return this.dialog.open(TjmLotDialogComponent, {
            width: '50%',
            panelClass: 'dialog-container',
            disableClose: true,
            data: {
                message: lot,
                currency: moneyType
            }
        });
    };
    DialogService.prototype.openDetailsTjmLotDialog = function (lot) {
        return this.dialog.open(TjmInfoDialogComponent, {
            width: '50%',
            panelClass: 'dialog-container',
            disableClose: true,
            data: {
                message: lot
            }
        });
    };
    DialogService.prototype.openInfoDialog = function (msg) {
        return this.dialog.open(InfoDialogComponent, {
            width: '600px',
            panelClass: 'dialog-container',
            disableClose: true,
            data: {
                message: msg
            }
        });
    };
    DialogService.prototype.openLockedDialog = function (msg) {
        return this.dialog.open(LockedDialogComponent, {
            width: '600px',
            panelClass: 'dialog-container',
            disableClose: true,
            data: {
                message: msg
            }
        });
    };
    DialogService.prototype.openUploadDialog = function (data) {
        return this.dialog.open(UploadDialogComponent, {
            width: '40%',
            panelClass: 'dialog-container',
            disableClose: true,
            data: data
        });
    };
    DialogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.ɵɵinject(i1.MatDialog)); }, token: DialogService, providedIn: "root" });
    return DialogService;
}());
export { DialogService };
