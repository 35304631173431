import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import {Observable} from 'rxjs';
import {User} from '../../admin/shared/user.model';
import { Employee } from '../../employees/shared/employee.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  username: string;
  password: string;
  authorities: any;
  user: User;
  encodedAuthentification = false;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private cookie: CookieService,
    private toastr: ToastrService,
  ) {
  }

  public login(username: string, password: string): Observable<any> {
    this.username = username;
    this.password = password;
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    params.append('grant_type', 'password' );
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      Authorization: 'Basic ' + btoa('Andricebrain:Aqwzsx1995')
    });

    const options = {
      headers
    };
    return this.httpClient
      .post(
        environment.baseUrlOauth + '/oauth/token',
        params.toString(),
        options
      );
  }

  getAuthorities(token) {
    const headers = new HttpHeaders({
      'Content-type': 'application/json; charset=utf-8',
      Authorization: 'Basic ' + btoa('Andricebrain:Aqwzsx1995')
    });
    const options = {
      headers
    };
    return this.httpClient
      .get(
        environment.baseUrlOauth +
          '/oauth/check_token?token=' +
          token.access_token,
        options
      );
  }

  // parse url to authentificate user
  private decodeSignedRequest(signedString: string): any {
    this.encodedAuthentification = true;
    // decode in base 64
    const decodedAuthString = atob(signedString);
    // return parsed Json
    return JSON.parse(decodedAuthString);
  }

  saveAuthInfo(signedString: string){
    const jsonAuth = this.decodeSignedRequest(signedString);
    this.saveAuth(jsonAuth);
  }

  private saveAuth(signedRequest: any) {
    const expireDate = new Date().getTime() + 1000 * signedRequest.token.expires_in;
    console.log("ici");
    this.cookie.set('access_token', signedRequest.token.access_token, expireDate, '/','andricebrain.fr', true, "None");
    this.cookie.set('refresh_token', signedRequest.token.refresh_token, expireDate, '/','andricebrain.fr', true, "None");
    this.cookie.set('authorities', signedRequest.authorities, expireDate, '/','andricebrain.fr', true, "None");
    this.cookie.set('user', JSON.stringify(signedRequest.user), expireDate, '/','andricebrain.fr', true, "None");
    this.cookie.set('encodedAuthentification', 'true', expireDate, '/','andricebrain.fr', true, "None");
    localStorage.setItem('candidatesOA', JSON.stringify(signedRequest.candidatesOA));
    this.authorities = signedRequest.authorities;
    this.user = signedRequest.user;
    localStorage.setItem('BusinessManagerEmailCH',signedRequest.user.emailCH);
  }

  saveToken(token, check) {
    const expireDate = new Date().getTime() + 1000 * token.expires_in;
    this.cookie.set('access_token', token.access_token, expireDate,'/','',false,'Lax');
    this.cookie.set('refresh_token', token.refresh_token,expireDate,'/','',false,'Lax');
    this.cookie.set('authorities', check.authorities,expireDate,'/','',false,'Lax');
    this.cookie.set('user', JSON.stringify(check.user[0]),expireDate,'/','',false,'Lax');
    this.authorities = check.authorities;
    this.user = check.user[0];
  }

  // check if a user is logged
  checkCredentials(): boolean {
    this.encodedAuthentification = this.cookie.check('encodedAuthentification');
    return this.cookie.check('access_token');
  }

  getAuhtorities() {
    this.authorities = this.cookie.get('authorities').split(',');
    if ( this.cookie.get('user') ) {
      this.user = JSON.parse(this.cookie.get('user'));
    }
  }

  refreshToken() {
    const params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('refresh_token', this.cookie.get('refresh_token'));
    params.append('username', this.username);
    params.append('password', this.password);
    const headers = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      Authorization: 'Basic ' + btoa('Andricebrain:Aqwzsx1995')
    });
    const options = {
      headers
    };
    return this.httpClient
      .post(
        environment.baseUrlOauth + '/oauth/token',
        params.toString(),
        options
      );
  }

  getUserById(id): Observable<User> {
    return this.httpClient.get<User>(environment.baseUrlOauth + '/user/checkIfExist/' + id)
  }

  sendForgotPasswordEmail(data: any): Observable<any> {
    return this.httpClient.post<any>(environment.baseUrlMailsender + '/sender/forgotPassword/', data);
  }

  sendNewPassword(id, password: string): Observable<any> {
    return this.httpClient.put<User>(environment.baseUrlOauth + '/user/resetPassword/' + id, password)
  }

  getEmployeeByWorkMailForgotPassword(email: string): Observable<Employee> {
    return this.httpClient.get<Employee>(environment.baseUrlEmployees + '/employees/forgotPassword/'+ email)
  }
}
