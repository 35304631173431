export const environment = {
  production: true,
  baseUrlOauth: 'abs-oauth',
  baseUrlCandidates: 'abs-candidates',
  baseUrlAddresses: 'abs-addresses',
  baseUrlTemplate: 'abs-templategenerator',
  baseUrlMissionOrders: 'abs-missionorders',
  baseUrlMailsender: 'abs-mailsender',
  baseUrlEmployees: 'abs-employees',
  baseUrlContacts: 'abs-contacts',
  baseUrlClients: 'abs-clients',
  baseUrlAndriceOffer: 'abs-andriceoffers',
  baseUrlSuppliers: 'abs-suppliers'
};

