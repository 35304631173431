import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppService } from '../../../shared/app.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { User } from './user.model';
import { UserToPersistModel } from './userToPersist.model';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private getAllUsersUrl = environment.baseUrlOauth + '/user/all';
  private createUserUrl = environment.baseUrlOauth + '/user/add';
  private getUserByIdUrl = environment.baseUrlOauth + '/user/';

  constructor(
    private router: Router,
    private appService: AppService,
    private toastr: ToastrService,
    private http: HttpClient
  ) {
    this.usersSubject = new Subject<User[]>();
    this.usersObservable = this.usersSubject.asObservable();
    this.selectedUser = this.selectedUserSubject.asObservable();
  }

  routes = [];
  resourcesLoaded = false;

  private usersSubject: Subject<User[]>;
  usersObservable: Observable<User[]>;

  private selectedUserSubject = new Subject<User>();
  selectedUser: Observable<User>;

  getUsers(): Observable<User[]> {
    const users = new Subject<User[]>();
    this.appService.getResource(this.getAllUsersUrl).subscribe(
      response => {
        console.log('GET request successful', response);
        this.usersSubject.next(response);
        users.next(response);
      },
      error => {
        console.log('GET users error : ' + error);
      }
    );
    return users.asObservable();
  }

  trimValidator(control: FormControl) {
    if (control.value.startsWith(' ')) {
      return {
        trimError: { value: 'control has leading whitespace' }
      };
    }
    if (control.value.endsWith(' ')) {
      return {
        trimError: { value: 'control has trailing whitespace' }
      };
    }
    return null;
  }

  createUser(user: UserToPersistModel) {
    return new Observable((observer) => {
      this.appService.postResource(this.createUserUrl, user)
        .subscribe(
          (response) => {
            console.log('POST request successful', response);
            this.toastr.success('Utilisateur créé avec succès !', '', {timeOut: 2000});
            observer.next(response);
          },
          (error) => {
            let errorMessage = 'Erreur lors de la création de l\'utilisateur ! ';
            let msg = error.error.message;
            if (error.status === 400) {
              msg = msg.split('=').pop().split(',')[0];
              errorMessage += '(' + msg + ')';
            }
            if (error.status === 401) {
              msg = 'Utilisateur non authentifié';
              errorMessage += '(' + msg + ')';
            }
            if (error.status === 500 || error.status === 504 || error.status === 503) {
              msg = 'erreur côté serveur, veuillez contacter la team-IT';
              errorMessage += '(' + msg + ')';
            }
            this.toastr.error(errorMessage, 'Info:', {timeOut: 2000});
          },
          () => {
            observer.complete();
          }
        );
    }).pipe(first());
  }


  updateUser(user: UserToPersistModel, id: number): Observable<any> {
    return new Observable((observer) => {
      this.appService.putResource(this.getUserByIdUrl + id, user)
        .subscribe(
          (response) => {
            console.log('PUT request successful', response);
            this.toastr.success('Utilisateur mis à jour avec succès !', '', {timeOut: 2000});
            observer.next(response);
          },
          (error) => {
            let errorMessage = 'Erreur lors de la mise à jour de l\'utilisateur ! ';
            let msg = error.error.message;
            if (error.status === 400) {
              msg = msg.split('=').pop().split(',')[0];
              errorMessage += '(' + msg + ')';
            }
            if (error.status === 401) {
              msg = 'Utilisateur non authentifié';
              errorMessage += '(' + msg + ')';
            }
            if (error.status === 500 || error.status === 504 || error.status === 503) {
              msg = 'erreur côté serveur, veuillez contacter la team-IT';
              errorMessage += '(' + msg + ')';
            }
            this.toastr.error(errorMessage, 'Info:', {timeOut: 2000});
          },
          () => {
            observer.complete();
          }
        );
    }).pipe(first());
  }

  getUserById(id: number): Observable<User> {
    const user = new Subject<User>();
    this.appService.getResource(this.getUserByIdUrl + id)
        .subscribe(
          (response: User) => {
            console.log('GET request successful', response);
            this.selectedUserSubject.next(response);
            user.next(response);
          },
          error => {
            this.toastr.error('Cet Utilisateur n\'existe plus !', '', {
              timeOut: 2000
            });
            console.log('GET error : ', error);
          },
        );
    return user.asObservable();
  }

  deleteUserById(id: number): Observable<any> {
    const deleteSubject = new Subject<any>();
    this.appService.deleteResource(this.getUserByIdUrl + id)
      .subscribe(
        (response) => {
          console.log('DELETE request successful ', response);
          deleteSubject.next(response);
          this.toastr.info('Le compte utilisateur a été supprimé avec succès!', '', {timeOut: 2000});
        },
        (error) => {
          console.log('DELETE error : ', error);
          this.toastr.error('Erreur lors de la suppression du salarié !', '', {timeOut: 2000});
        }
      );
    return deleteSubject.asObservable();
  }

  resetPassword(data: any) {
    return new Observable(observer => {
      this.appService
        .putResource(this.getUserByIdUrl + 'preco', data)
        .subscribe(
          response => {
            this.toastr.success('Mot de passe changé avec succès !', '', {
              timeOut: 2000
            });
            observer.next(response);
          },
          error => {
            let errorMessage =
              'Erreur lors de la mise à jour ! ';
            let msg = error.message;
            if (error.status === 400) {
              msg = msg
                .split('=')
                .pop()
                .split(',')[0];
              errorMessage += '(' + msg + ')';
            }
            if (error.status === 404) {
              msg = 'Le mot de passe saisi est incorrect'
                .split('=')
                .pop()
                .split(',')[0];
              errorMessage += '(' + msg + ')';
            }
            if (error.status === 401) {
              msg = 'Utilisateur non authentifié';
              errorMessage += '(' + msg + ')';
            }
            if (
              error.status === 500 ||
              error.status === 504 ||
              error.status === 503
            ) {
              msg = 'erreur côté serveur, veuillez contacter la team-IT';
              errorMessage += '(' + msg + ')';
            }
            this.toastr.error(errorMessage, '', { timeOut: 2000 });
          },
          () => {
            observer.complete();
          }
        );
    }).pipe(first());
  }

  verifyPassword(data: any) {
    return new Observable(observer => {
      this.appService.postResource(this.getUserByIdUrl + 'verifyPassword', data).subscribe(
        (response) => {
          if (response.body !== null) {
            observer.next(response);
            localStorage.setItem('login', data.login);
            this.router.navigate(['/preco']);
          } else if (response.body === null) {
            observer.next(response);
            this.router.navigate(['/home']);
          }
        },
        error => {
          this.toastr.error('Cet Utilisateur n\'existe pas !', '', {
            timeOut: 2000
          });
          console.log('GET error : ', error);
        }
      );
    }).pipe(first());
  }

}
