import {Component} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppService} from 'src/app/shared/app.service';
import {AuthService} from '../../modules/auth/shared/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent {
  authorities: string[];
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver, private appService: AppService,
              public authService: AuthService, private router: Router) {
    this.authorities = this.authService.authorities;

  }

  logout() {
    this.appService.logout();
  }

  goToMyAccount() {
      this.router.navigate(['/settings/myaccount']);
  }


}
