import { Routes } from '@angular/router';
import { AdminComponent } from './admin.component';
import { UsersListComponent } from './users-list/users-list.component';
import { AddUserComponent } from './add-user/add-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { AuthGuardService } from '../auth/shared/auth-guard.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
var ɵ0 = { permissions: ['ADMIN_USERS_LIST'] }, ɵ1 = { permissions: ['ADMIN_CREATE_USER'] }, ɵ2 = { permissions: ['ADMIN_UPDATE_USER'] };
var routes = [
    {
        path: '',
        component: AdminComponent,
        children: [
            {
                path: '',
                canActivate: [AuthGuardService],
                data: ɵ0,
                component: UsersListComponent,
            },
            {
                path: 'add',
                canActivate: [AuthGuardService],
                data: ɵ1,
                component: AddUserComponent
            },
            {
                path: 'edit/:idEmployee',
                canActivate: [AuthGuardService],
                data: ɵ2,
                component: EditUserComponent
            },
        ]
    },
    {
        path: 'password',
        outlet: 'reset',
        component: ResetPasswordComponent,
    },
];
var AdminRoutingModule = /** @class */ (function () {
    function AdminRoutingModule() {
    }
    return AdminRoutingModule;
}());
export { AdminRoutingModule };
export { ɵ0, ɵ1, ɵ2 };
