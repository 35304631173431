import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';


@Component({
  selector: 'app-locked-dialog',
  templateUrl: './locked-dialog.component.html',
  styleUrls: ['./locked-dialog.component.scss']
})
export class LockedDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LockedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }

}
