import {NativeDateAdapter} from '@angular/material';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FrenchDateAdapter extends NativeDateAdapter {

  getFirstDayOfWeek(): number {
    return 1;
  }

}
