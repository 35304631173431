import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NotFoundComponent} from './modules/not-found/not-found.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// Import services
import {CoreModule} from './core/core.module';
import {AppService} from './shared/app.service';
import {ToastrModule} from 'ngx-toastr';



@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-full-width',
      preventDuplicates: true,
    }),
  ],
  providers: [
    AppService,
  ],

  bootstrap: [AppComponent],
})
export class AppModule { }
