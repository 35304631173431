import { Router } from '@angular/router';
import { AppService } from 'src/app/shared/app.service';
import { DialogService } from '../../../shared/dialog.service';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/app.service";
import * as i2 from "@angular/router";
import * as i3 from "../../../shared/dialog.service";
import * as i4 from "./auth.service";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(appService, router, dialogService, auth) {
        this.appService = appService;
        this.router = router;
        this.dialogService = dialogService;
        this.auth = auth;
    }
    AuthGuardService.prototype.canActivate = function (route, state) {
        var urlwithoutParams = state.url.split('?')[0];
        if (this.auth.checkCredentials()) {
            // If authorities contains all the permissions that route permissions require , we check user credentials and can display page
            // If not, we open a dialog saying that we don't have rights
            //
            this.auth.getAuhtorities();
            var permissions_1 = [];
            route.data.permissions ? permissions_1 = route.data.permissions : permissions_1 = [];
            if (this.auth.authorities.some(function (perm) { return permissions_1.includes(perm); }) || permissions_1.length === 0) {
                if (route.queryParamMap.has('sendRequest')) {
                    this.auth.saveAuthInfo(route.queryParamMap.get('sendRequest'));
                    this.router.navigate([urlwithoutParams]);
                }
                return this.auth.checkCredentials();
            }
            else {
                // role not authorised
                this.dialogService.openInfoDialog('Vous n\'avez pas le droit d\'accéder à cette page !');
            }
        }
        else {
            if (route.queryParamMap.has('sendRequest')) {
                this.auth.saveAuthInfo(route.queryParamMap.get('sendRequest'));
                this.router.navigate([urlwithoutParams]);
            }
            else {
                this.router.navigate(['/auth']);
            }
        }
    };
    AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.AppService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.DialogService), i0.ɵɵinject(i4.AuthService)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
