import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatPaginatorModule, MatSortModule} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import {SharedModule} from '../../shared/shared.module';
import {AdminRoutingModule} from './admin-routing.module';
import {UsersListComponent} from './users-list/users-list.component';
import {EditUserComponent} from './edit-user/edit-user.component';
import {AddUserComponent} from './add-user/add-user.component';
import {AdminService} from './shared/admin.service';
import {AdminComponent} from './admin.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';

@NgModule({
  declarations: [
    UsersListComponent,
    AddUserComponent,
    ResetPasswordComponent,
    AdminComponent,
    EditUserComponent
  ],
  exports: [
    ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    SharedModule,
    AdminRoutingModule,
    MatSortModule
  ],
  providers: [AdminService]

})
export class AdminModule { }
