import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler, HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {BehaviorSubject, Observable, of, pipe, Subject, throwError} from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import {catchError, finalize, tap, filter, map, first, take, switchMap, startWith} from 'rxjs/operators';
import { AuthService } from '../../modules/auth/shared/auth.service';
import { ToastrService } from 'ngx-toastr';




@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  isRefreshingToken = false;
  constructor(
    private httpClient: HttpClient,
    private cookie: CookieService,
    private router: Router,
    private appService: AppService,
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((ev: HttpEvent<any>) => {
      }),
      catchError((error: HttpErrorResponse) => {
        //
        // Catch token errors and handle them
        //
        if (error.status === 401) {
          //
          // logout if refresh token expired
          //
          if ( error.error.error === 'invalid_token') {
            if ( error.error.error_description.includes('refresh token')) {
              this.isRefreshingToken = false;
              this.toastr.error('', 'Session Expirée');
              this.appService.logout();
              return throwError(error);
            } else {
              return this.handle401Error(req, next);
            }
          } else {
            return throwError(error);
          }
        } else {
            return throwError(error);
          }

        }
      ));
  }

  //
  // Clone request if access token expired
  // If refresh token expired, then logout
  //
  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //
    // if token is refreshing, clone request
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.tokenSubject.next(null);

      //
      // refresh token , if success, clone request with new token and set refreshing token task to false
      //
      return this.authService
          .refreshToken().pipe(
          switchMap((token: any) => {
            this.store(token);
            this.isRefreshingToken = false;
            this.tokenSubject.next(token.access_token);
            return next.handle(this.cloneRequest(request, token.access_token));
          }),
          catchError((err) => {
            this.isRefreshingToken = false;
            this.appService.logout();
            return throwError('Erreur lors du refresh du token, Deconnexion'); }));

    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.cloneRequest(request, token));
        }));
    }
  }

  //
  // store tokens in cookies
  //
  store(tokens) {
    const expireDate = new Date().getTime() + 1000 * tokens.expires_in;
    this.cookie.set('access_token', tokens.access_token, expireDate);
    this.cookie.set('refresh_token', tokens.refresh_token,expireDate);
    this.cookie.set('user', JSON.stringify(tokens.user[0]),expireDate);
  }


  //
  // Return clone request with new token in headers
  //
  cloneRequest(req: HttpRequest<any>, token: string): HttpRequest<any> {
    const headers = new HttpHeaders({
      'Content-type': 'application/json; charset=utf-8',
      Authorization: 'Bearer ' + token
    });

    const request = req.clone({headers});

    return request;

  }
}






