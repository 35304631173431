<mat-sidenav-container class="sidenav-container">
  <mat-sidenav *ngIf="!authService.encodedAuthentification" #drawer class="sidenav" fixedInViewport="false"
               [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
               [mode]="(isHandset$ | async) ? 'over' : 'side'"
               [opened]="!(isHandset$ | async)">
    <mat-toolbar><img mat-card-image
                      [routerLink]="['/home']"
                      src="/assets/img/logo.png" class="logo"
                      alt="andrice logo"></mat-toolbar>
    <mat-nav-list>
      <a *ngIf="authorities.includes('CANDIDATES')"mat-list-item class="navigationMenu" [routerLink]="['/candidates']">CANDIDAT</a>
      <a *ngIf="authorities.includes('EMPLOYEES')"mat-list-item class="navigationMenu" [routerLink]="['/employees']">SALARIÉ</a>
      <a *ngIf="authorities.includes('ALL_MISSION_ORDER')" mat-list-item class="navigationMenu" [routerLink]="['/missionOrders']">ORDRE DE MISSION</a>
      <a *ngIf="authorities.includes('CLIENTS')"mat-list-item class="navigationMenu" [routerLink]="['/clients']">CLIENT</a>
      <a  *ngIf="authorities.includes('SUPPLIERS')" mat-list-item class="navigationMenu" [routerLink]="['/suppliers']">FOURNISSEUR</a>
      <a mat-list-item class="navigationMenu" [routerLink]="['/affectations']">AFFECTATION</a>
      <a *ngIf="authorities.includes('DASHBOARD')"mat-list-item class="navigationMenu" [routerLink]="['/dashboard']">TABLEAU DE BORD</a>
      <a *ngIf="authorities.includes('ADMIN_USERS_LIST')" mat-list-item class="navigationMenu" [routerLink]="['/users']">UTILISATEURS</a>
      <a *ngIf="authorities.includes('BOONDVIEW')" mat-list-item class="navigationMenu" [routerLink]="['/boondview']">BOOND</a>

    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar *ngIf="!authService.encodedAuthentification" class="toolbar">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class="spacer"></span>
<!--      <mat-icon>person_outline</mat-icon>-->
      <span class="connectedUsername">{{authService.user.firstName}}</span>
      <button mat-button title="Options de l'utilisateur" [matMenuTriggerFor]="menu" aria-label="User Option Menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="goToMyAccount()">
            <mat-icon>account_circle</mat-icon>
            <span>Profil</span>
          </button>
        </mat-menu>
      <button mat-button class="deconnexion" title="Déconnexion" (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </mat-toolbar>
    <router-outlet></router-outlet>
    <!-- Router outlet inject here -->
  </mat-sidenav-content>
</mat-sidenav-container>
