import { AuthService } from './../modules/auth/shared/auth.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DataService {

    dataUrl = '/assets/data';
    constructor(private http: HttpClient, private authService: AuthService) {}

    getCountries(): Observable<any> {
        return this.http.get<any>(`${this.dataUrl}/nationalities.json`);
    }

    getEmployeeData(): Observable<any> {
        return this.http.get<any>(`${this.dataUrl}/employeeData.json`);
    }

    getContactData(): Observable<any> {
        return this.http.get<any>(`${this.dataUrl}/contactData.json`);
    }

    getClientData(): Observable<any> {
        return this.http.get<any>(`${this.dataUrl}/clientData.json`);
    }

    getAndriceOfferData(): Observable<any> {
        return this.http.get<any>(`${this.dataUrl}/andriceOfferData.json`);
    }

    getUserRoles(): Observable<any> {
        return this.http.get<any>(`${this.dataUrl}/user_role.json`);
    }

    getTimeData(): Observable<any> {
        return this.http.get<any>(`${this.dataUrl}/timeData.json`);
    }

    getSupplierType(): Observable<any> {
      return this.http.get<any>(`${this.dataUrl}/supplierData.json`);
  }
    defineInfosAndrice(form: FormGroup, family: string): string[] {
        let families = [''];
        switch (this.authService.user.role) {
          case 'ROLE_RH':
            if (family !== 'Ingénierie') {
              families = ['Siège', 'Business'];
            } else {
              families = ['Ingénierie'];
              form.get('family').disable();
              form.get('category').disable();
              form.get('status').disable();
              form.get('schoolYear').disable();
              form.get('position').disable();
              form.get('businessUnit').disable();
            }
            break;
          case 'ROLE_RRH':
            if (family !== 'Ingénierie') {
              families = ['Siège', 'Business'];
            } else {
              families = ['Ingénierie'];
              form.get('family').disable();
              form.get('category').disable();
              form.get('status').disable();
              form.get('schoolYear').disable();
              form.get('position').disable();
              form.get('businessUnit').disable();
            }
            break;
          case 'ROLE_BM_SENIOR':
            if (family === 'Ingénierie') {
              families = ['Ingénierie'];
            } else {
              form.get('family').disable();
              form.get('category').disable();
              form.get('status').disable();
              form.get('schoolYear').disable();
              form.get('position').disable();
              form.get('businessUnit').disable();
            }
            break;
          case 'ROLE_BM_JUNIOR':
            if (family === 'Ingénierie') {
              families = ['Ingénierie'];
            } else {
              form.get('family').disable();
              form.get('category').disable();
              form.get('status').disable();
              form.get('schoolYear').disable();
              form.get('position').disable();
              form.get('businessUnit').disable();
            }
            break;
          case 'ROLE_PRESIDENT':
            families = ['Siège', 'Business', 'Ingénierie'];
            break;
          default:
            families = ['Siège', 'Business', 'Ingénierie'];
            break;
        }
        return families.sort();
      }
  
  
}
