import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material';
import {InfoDialogComponent} from './dialogs/info-dialog/info-dialog.component';
import {TjmLotDialogComponent} from './dialogs/tjm-lot-dialog/tjm-lot-dialog.component';
import {Lot} from '../modules/andrice-offers/shared/lot.model';
import {TjmInfoDialogComponent} from './dialogs/tjm-info-dialog/tjm-info-dialog.component';
import { UploadDialogComponent } from './dialogs/upload-dialog/upload-dialog.component';
import { LockedDialogComponent } from './dialogs/locked-dialog/locked-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) {
  }

  openConfirmDialog(msg: string) {
    return this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      panelClass: 'dialog-container',
      disableClose: true,
      data: {
        message: msg
      }
    });
  }

  openTjmLotDialog(lot: Lot, moneyType: string) {
    return this.dialog.open(TjmLotDialogComponent, {
      width: '50%',
      panelClass: 'dialog-container',
      disableClose: true,
      data: {
        message: lot,
        currency: moneyType
      }
    });
  }

  openDetailsTjmLotDialog(lot: Lot) {
    return this.dialog.open(TjmInfoDialogComponent, {
      width: '50%',
        panelClass: 'dialog-container',
        disableClose: true,
        data: {
        message: lot
      }
    });
  }


  openInfoDialog(msg: string) {
    return this.dialog.open(InfoDialogComponent, {
      width: '600px',
      panelClass: 'dialog-container',
      disableClose: true,
      data: {
        message: msg
      }
    });
  }

  openLockedDialog(msg: string) {
    return this.dialog.open(LockedDialogComponent, {
      width: '600px',
      panelClass: 'dialog-container',
      disableClose: true,
      data: {
        message: msg
      }
    });
  }

  openUploadDialog(data: any) {
    return this.dialog.open(UploadDialogComponent, {
      width: '40%',
      panelClass: 'dialog-container',
      disableClose: true,
      data
    });
  }

}
