import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-cookie-service";
import * as i4 from "ngx-toastr";
var AuthService = /** @class */ (function () {
    function AuthService(router, httpClient, cookie, toastr) {
        this.router = router;
        this.httpClient = httpClient;
        this.cookie = cookie;
        this.toastr = toastr;
        this.encodedAuthentification = false;
    }
    AuthService.prototype.login = function (username, password) {
        this.username = username;
        this.password = password;
        var params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);
        params.append('grant_type', 'password');
        var headers = new HttpHeaders({
            'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            Authorization: 'Basic ' + btoa('Andricebrain:Aqwzsx1995')
        });
        var options = {
            headers: headers
        };
        return this.httpClient
            .post(environment.baseUrlOauth + '/oauth/token', params.toString(), options);
    };
    AuthService.prototype.getAuthorities = function (token) {
        var headers = new HttpHeaders({
            'Content-type': 'application/json; charset=utf-8',
            Authorization: 'Basic ' + btoa('Andricebrain:Aqwzsx1995')
        });
        var options = {
            headers: headers
        };
        return this.httpClient
            .get(environment.baseUrlOauth +
            '/oauth/check_token?token=' +
            token.access_token, options);
    };
    // parse url to authentificate user
    AuthService.prototype.decodeSignedRequest = function (signedString) {
        this.encodedAuthentification = true;
        // decode in base 64
        var decodedAuthString = atob(signedString);
        // return parsed Json
        return JSON.parse(decodedAuthString);
    };
    AuthService.prototype.saveAuthInfo = function (signedString) {
        var jsonAuth = this.decodeSignedRequest(signedString);
        this.saveAuth(jsonAuth);
    };
    AuthService.prototype.saveAuth = function (signedRequest) {
        var expireDate = new Date().getTime() + 1000 * signedRequest.token.expires_in;
        console.log("ici");
        this.cookie.set('access_token', signedRequest.token.access_token, expireDate, '/', 'andricebrain.fr', true, "None");
        this.cookie.set('refresh_token', signedRequest.token.refresh_token, expireDate, '/', 'andricebrain.fr', true, "None");
        this.cookie.set('authorities', signedRequest.authorities, expireDate, '/', 'andricebrain.fr', true, "None");
        this.cookie.set('user', JSON.stringify(signedRequest.user), expireDate, '/', 'andricebrain.fr', true, "None");
        this.cookie.set('encodedAuthentification', 'true', expireDate, '/', 'andricebrain.fr', true, "None");
        localStorage.setItem('candidatesOA', JSON.stringify(signedRequest.candidatesOA));
        this.authorities = signedRequest.authorities;
        this.user = signedRequest.user;
        localStorage.setItem('BusinessManagerEmailCH', signedRequest.user.emailCH);
    };
    AuthService.prototype.saveToken = function (token, check) {
        var expireDate = new Date().getTime() + 1000 * token.expires_in;
        this.cookie.set('access_token', token.access_token, expireDate, '/', '', false, 'Lax');
        this.cookie.set('refresh_token', token.refresh_token, expireDate, '/', '', false, 'Lax');
        this.cookie.set('authorities', check.authorities, expireDate, '/', '', false, 'Lax');
        this.cookie.set('user', JSON.stringify(check.user[0]), expireDate, '/', '', false, 'Lax');
        this.authorities = check.authorities;
        this.user = check.user[0];
    };
    // check if a user is logged
    AuthService.prototype.checkCredentials = function () {
        this.encodedAuthentification = this.cookie.check('encodedAuthentification');
        return this.cookie.check('access_token');
    };
    AuthService.prototype.getAuhtorities = function () {
        this.authorities = this.cookie.get('authorities').split(',');
        if (this.cookie.get('user')) {
            this.user = JSON.parse(this.cookie.get('user'));
        }
    };
    AuthService.prototype.refreshToken = function () {
        var params = new URLSearchParams();
        params.append('grant_type', 'refresh_token');
        params.append('refresh_token', this.cookie.get('refresh_token'));
        params.append('username', this.username);
        params.append('password', this.password);
        var headers = new HttpHeaders({
            'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            Authorization: 'Basic ' + btoa('Andricebrain:Aqwzsx1995')
        });
        var options = {
            headers: headers
        };
        return this.httpClient
            .post(environment.baseUrlOauth + '/oauth/token', params.toString(), options);
    };
    AuthService.prototype.getUserById = function (id) {
        return this.httpClient.get(environment.baseUrlOauth + '/user/checkIfExist/' + id);
    };
    AuthService.prototype.sendForgotPasswordEmail = function (data) {
        return this.httpClient.post(environment.baseUrlMailsender + '/sender/forgotPassword/', data);
    };
    AuthService.prototype.sendNewPassword = function (id, password) {
        return this.httpClient.put(environment.baseUrlOauth + '/user/resetPassword/' + id, password);
    };
    AuthService.prototype.getEmployeeByWorkMailForgotPassword = function (email) {
        return this.httpClient.get(environment.baseUrlEmployees + '/employees/forgotPassword/' + email);
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.CookieService), i0.ɵɵinject(i4.ToastrService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
