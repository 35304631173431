import { FirstLoginComponent } from './modules/auth/first-login/first-login.component';
import { Routes } from '@angular/router';
import { AuthComponent } from './modules/auth/auth.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { MainNavComponent } from './core/main-nav/main-nav.component';
import { AuthGuardService } from './modules/auth/shared/auth-guard.service';
import { ForgotPasswordComponent } from './modules/auth/forgot-password/forgot-password.component';
var ɵ0 = function () { return import("./modules/home/home.module.ngfactory").then(function (mod) { return mod.HomeModuleNgFactory; }); }, ɵ1 = function () { return import("./modules/candidates/candidates.module.ngfactory").then(function (mod) { return mod.CandidatesModuleNgFactory; }); }, ɵ2 = function () { return import("./modules/clients/clients.module.ngfactory").then(function (mod) { return mod.ClientsModuleNgFactory; }); }, ɵ3 = function () { return import("./modules/suppliers/suppliers.module.ngfactory").then(function (mod) { return mod.SuppliersModuleNgFactory; }); }, ɵ4 = function () { return import("./modules/employees/employees.module.ngfactory").then(function (mod) { return mod.EmployeesModuleNgFactory; }); }, ɵ5 = function () { return import("./modules/mission-orders/mission-orders.module.ngfactory").then(function (mod) { return mod.MissionOrdersModuleNgFactory; }); }, ɵ6 = function () { return import("./modules/dashboard/dashboard.module.ngfactory").then(function (mod) { return mod.DashboardModuleNgFactory; }); }, ɵ7 = function () { return import("./modules/admin/admin.module.ngfactory").then(function (mod) { return mod.AdminModuleNgFactory; }); }, ɵ8 = function () { return import("./modules/settings/settings.module.ngfactory").then(function (mod) { return mod.SettingsModuleNgFactory; }); }, ɵ9 = function () { return import("./modules/boondview/boondview.module.ngfactory").then(function (mod) { return mod.BoondviewModuleNgFactory; }); };
var appRoutes = [
    { path: 'auth', component: AuthComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'preco', canActivate: [AuthGuardService], component: FirstLoginComponent },
    { path: '',
        canActivate: [AuthGuardService],
        component: MainNavComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'home'
            },
            {
                path: 'home',
                loadChildren: ɵ0
            },
            {
                path: 'candidates',
                loadChildren: ɵ1,
            },
            {
                path: 'clients',
                loadChildren: ɵ2,
            },
            {
                path: 'suppliers',
                loadChildren: ɵ3,
            },
            {
                path: 'employees',
                loadChildren: ɵ4,
            },
            {
                path: 'missionOrders',
                loadChildren: ɵ5,
            },
            {
                path: 'dashboard',
                loadChildren: ɵ6,
            },
            {
                path: 'users',
                loadChildren: ɵ7
            },
            {
                path: 'settings',
                loadChildren: ɵ8
            },
            {
                path: 'boondview',
                loadChildren: ɵ9
            }
        ],
    },
    { path: 'not-found', component: NotFoundComponent },
    { path: '**', redirectTo: 'not-found' },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
