import {Injectable} from '@angular/core';
import {WorkingDay} from '../../modules/andrice-offers/shared/working-day.model';
import {Lot} from '../../modules/andrice-offers/shared/lot.model';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateOperationUtil {
  private static datePipe: DatePipe;
  constructor() {
  }

  static numberWorkingDayYear(an: number) {
    const begin = new Date(an, 0, 1);
    const end = new Date(an, 11, 31);
    return this.getWorkingDays(begin, end);
  }

  static getWorkingDays(startDate, endDate) {
    let result = 0;
    let nbJoursFeries = 0;
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const weekDay = currentDate.getDay();

      if (weekDay !== 0 && weekDay !== 6) {
        result++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    if (startDate.getFullYear() === endDate.getFullYear()) {
      nbJoursFeries = this.nbOffDays(startDate, endDate);
    } else {
      let startDateYear = startDate.getFullYear();
      const endDateYear  = endDate.getFullYear();
      while (startDateYear <= endDateYear) {
        if (startDateYear < endDateYear) {
          nbJoursFeries += this.nbOffDays(startDate, new Date(startDateYear, 12, 31));
          startDate = new Date(startDateYear + 1, 0, 1);
          startDateYear += 1;
        } else {
          nbJoursFeries += this.nbOffDays(startDate, endDate);
          startDateYear += 1;
        }
      }
    }
    result -= nbJoursFeries;
    return result;
  }

  static JoursFeries(an) {
    const JourAn = new Date(an, 0, 1);
    const FeteTravail = new Date(an, 4, 1);
    const Victoire1945 = new Date(an, 4, 8);
    const FeteNationale = new Date(an, 6, 14);
    const Assomption = new Date(an, 7, 15);
    const Toussaint = new Date(an, 10, 1);
    const Armistice = new Date(an, 10, 11);
    const Noel = new Date(an, 11, 25);
    // const SaintEtienne = new Date(an, 11, 26);

    const G = an % 19;
    const C = Math.floor(an / 100);
    const H = (C - Math.floor(C / 4) - Math.floor((8 * C + 13) / 25) + 19 * G + 15) % 30;
    const I = H - Math.floor(H / 28) * (1 - Math.floor(H / 28) * Math.floor(29 / (H + 1)) * Math.floor((21 - G) / 11));
    const J = (an * 1 + Math.floor(an / 4) + I + 2 - C + Math.floor(C / 4)) % 7;
    const L = I - J;
    const MoisPaques = 3 + Math.floor((L + 40) / 44);
    const JourPaques = L + 28 - 31 * Math.floor(MoisPaques / 4);
    const LundiPaques = new Date(an, MoisPaques - 1, JourPaques + 1);
    const Ascension = new Date(an, MoisPaques - 1, JourPaques + 39);
    // const LundiPentecote = new Date(an, MoisPaques - 1, JourPaques + 50);

    const TabOffDay = [JourAn, LundiPaques, FeteTravail, Victoire1945, Ascension,
      FeteNationale, Assomption, Toussaint, Armistice, Noel];

    return TabOffDay;
  }

  static nbOffDays(startDate, endDate) {
    let NbOffDays = 0;
    const TabOffDay = this.JoursFeries(startDate.getFullYear());
    TabOffDay.forEach(day => {
        // si le jour ferie n'est pas un samedi ou un dimanche
        if (day.getDay() !== 6 && day.getDay() !== 0) {
          // on test que le jour férié est compris entre startDate et endDate
          if (day >= startDate && day <= endDate) {
            NbOffDays++;
          }
        }
      }
    );
    return NbOffDays;
  }

  static getFirstDayOfNextMonth(date: Date) {
    // retourne le premier jour du mois suivant
    if (date.getMonth() === 11) {
      return new Date(date.getFullYear() + 1, 0, 1);
    } else {
      return new Date(date.getFullYear(), date.getMonth() + 1, 1);
    }
  }

  static getEndDate(startingDate: Date, endingDate: Date) {
    if ( startingDate.getMonth() !== endingDate.getMonth()
      || ( (startingDate.getFullYear() !== endingDate.getFullYear()) &&  startingDate.getMonth() === endingDate.getMonth())) {
      return new Date(startingDate.getFullYear(), startingDate.getMonth() + 1, 0, 0, 0, 0);
    } else {
      return endingDate;
    }
  }

  static getAllMonth(firstDate: Date, secondDate: Date) {
    // recuperation de la liste des mois entre une intervalle de date
    const months: number[] = [];
    let nbMonth = 0;
    if (firstDate.getFullYear() === secondDate.getFullYear()) {
      // si on a la meme annee pour la date de debut et la date de fin, le nombre de
      // mois sera la difference entre le mois de la date de fin et celui de la date de debut
      nbMonth = secondDate.getMonth() - firstDate.getMonth();
    } else {
      // si nous avons des annees differentes alors on recupere les 2 annees
      // et tant que i est != de la deuxieme annee on fait la difference entre le 12mois et le mois de la premiere date et
      // notre nouvelle date passe au premier jour de l'annee suivante soit le 1 janvier
      // qi i est === a l'annee de la seconde date alors on recupere juste le mois de la seconde anee +1 qu'on ajoute a notre nombre de mois

      // nbMonth = Math.abs(Math.round(diff));
      const firstYear = Number(firstDate.getFullYear());
      let usedFirstDay = firstDate;
      const secondYear = Number(secondDate.getFullYear());

      for (let i = firstYear; i <= secondYear; i++) {
        if (i !== secondYear) {
          nbMonth += 11 - usedFirstDay.getMonth();
          usedFirstDay = new Date(i + 1, 1, 1);
        } else {
          nbMonth += secondDate.getMonth() + 1;
        }
      }

    }
    let date = firstDate;
    for (let i = 0; i <= nbMonth; i++) {
      months.push(date.getMonth());
      date = this.getFirstDayOfNextMonth(date);
    }
    return months;

  }

  static getWorkingDayJo(lot: Lot) {
    // get the stating Date and the ending date of the lot
    const workingDays: WorkingDay[] = [];
    const firstDate = new Date(lot.startDate.getFullYear(), lot.startDate.getMonth(), lot.startDate.getDate(), 0, 0, 0);
    const secondDate = new Date(lot.endDate.getFullYear(), lot.endDate.getMonth(), lot.endDate.getDate(), 0, 0, 0);
    // getting the number of the months betweens the first and the second date
    const months: number[] = this.getAllMonth(firstDate, secondDate);
    // tslint:disable-next-line:label-position prefer-const
    let workingDay: WorkingDay;
    let firstDayNextMonth: Date = null;
    // for each month
    for ( let i = 0; i < months.length; i++) {
      // create a working day object and calculate the number of workingDays for each month
      workingDay = new WorkingDay();
      workingDay.month = months[i] + 1;
      workingDay.amount = lot.mdr;
      workingDay.idLot = lot.id;
      workingDay.id = null;
      if (i === 0 ) {
        workingDay.nbDays = this.getWorkingDays(firstDate, this.getEndDate(firstDate, secondDate));
        firstDayNextMonth = this.getFirstDayOfNextMonth(firstDate);
      } else {
        workingDay.nbDays = this.getWorkingDays(
          firstDayNextMonth, this.getEndDate(firstDayNextMonth, secondDate));
        firstDayNextMonth = this.getFirstDayOfNextMonth(this.getEndDate(firstDayNextMonth, secondDate));
      }
      workingDays.push(workingDay);
    }
    return workingDays;
  }

  static formatDate(date: Date) {
    return this.datePipe.transform(date, 'short');
  }

  // function to filter dates disable weekend, french holidays and previous dates in the datepicker
  static signatureDateFilter = (d: Date): boolean => {
    const day = d.getDay();
    let holidays = [];
    const yearsList = [];
    const minYear = d.getFullYear() - 1;
    const maxYear = d.getFullYear() + 1;
    for (let y = minYear; y <= maxYear; y++) {
      yearsList.push(y);
    }
    yearsList.forEach(year => {
      holidays = holidays.concat(DateOperationUtil.JoursFeries(year));
    });
    const holidaysDates = [];
    holidays.forEach(hDate => {
      holidaysDates.push(hDate.toDateString());
    });
    return day !== 0 && day !== 6 && holidaysDates.indexOf(d.toDateString()) === -1 && d > new Date();
  }

  // function to filter dates disable weekend, french holidays and previous month in the datepicker
  static odmEmployeeDateFilter = (d: Date): boolean => {
    const day = d.getDay();
    const startMonth = new Date();
    let holidays = [];
    const yearsList = [];
    const minYear = d.getFullYear() - 1;
    const maxYear = d.getFullYear() + 1;
    for (let y = minYear; y <= maxYear; y++) {
      yearsList.push(y);
    }
    yearsList.forEach(year => {
      holidays = holidays.concat(DateOperationUtil.JoursFeries(year));
    });
    const holidaysDates = [];
    holidays.forEach(hDate => {
      holidaysDates.push(hDate.toDateString());
    });
    // set the date on the first day to the current month
    startMonth.setDate(1);
    return day !== 0 && day !== 6 && holidaysDates.indexOf(d.toDateString()) === -1 && d > startMonth;
  }

  static day_of_the_month(d) {
    // function to filter dates disable weekend, french holidays and previous dates in the datepicker
    return (d.getDate() < 10 ? '0' : '') + d.getDate();
  }

  static month_of_the_year(d) {
    const month = d.getMonth() + 1;
    return (month < 10 ? '0' : '') + month;
  }

}
