import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { catchError, tap, filter, take, switchMap } from 'rxjs/operators';
import { AuthService } from '../../modules/auth/shared/auth.service';
import { ToastrService } from 'ngx-toastr';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
import * as i3 from "@angular/router";
import * as i4 from "../app.service";
import * as i5 from "../../modules/auth/shared/auth.service";
import * as i6 from "ngx-toastr";
var TokenInterceptorService = /** @class */ (function () {
    function TokenInterceptorService(httpClient, cookie, router, appService, authService, toastr) {
        this.httpClient = httpClient;
        this.cookie = cookie;
        this.router = router;
        this.appService = appService;
        this.authService = authService;
        this.toastr = toastr;
        this.tokenSubject = new BehaviorSubject(null);
        this.isRefreshingToken = false;
    }
    TokenInterceptorService.prototype.intercept = function (req, next) {
        var _this = this;
        return next.handle(req).pipe(tap(function (ev) {
        }), catchError(function (error) {
            //
            // Catch token errors and handle them
            //
            if (error.status === 401) {
                //
                // logout if refresh token expired
                //
                if (error.error.error === 'invalid_token') {
                    if (error.error.error_description.includes('refresh token')) {
                        _this.isRefreshingToken = false;
                        _this.toastr.error('', 'Session Expirée');
                        _this.appService.logout();
                        return throwError(error);
                    }
                    else {
                        return _this.handle401Error(req, next);
                    }
                }
                else {
                    return throwError(error);
                }
            }
            else {
                return throwError(error);
            }
        }));
    };
    //
    // Clone request if access token expired
    // If refresh token expired, then logout
    //
    TokenInterceptorService.prototype.handle401Error = function (request, next) {
        var _this = this;
        //
        // if token is refreshing, clone request
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            this.tokenSubject.next(null);
            //
            // refresh token , if success, clone request with new token and set refreshing token task to false
            //
            return this.authService
                .refreshToken().pipe(switchMap(function (token) {
                _this.store(token);
                _this.isRefreshingToken = false;
                _this.tokenSubject.next(token.access_token);
                return next.handle(_this.cloneRequest(request, token.access_token));
            }), catchError(function (err) {
                _this.isRefreshingToken = false;
                _this.appService.logout();
                return throwError('Erreur lors du refresh du token, Deconnexion');
            }));
        }
        else {
            return this.tokenSubject.pipe(filter(function (token) { return token != null; }), take(1), switchMap(function (token) {
                return next.handle(_this.cloneRequest(request, token));
            }));
        }
    };
    //
    // store tokens in cookies
    //
    TokenInterceptorService.prototype.store = function (tokens) {
        var expireDate = new Date().getTime() + 1000 * tokens.expires_in;
        this.cookie.set('access_token', tokens.access_token, expireDate);
        this.cookie.set('refresh_token', tokens.refresh_token, expireDate);
        this.cookie.set('user', JSON.stringify(tokens.user[0]), expireDate);
    };
    //
    // Return clone request with new token in headers
    //
    TokenInterceptorService.prototype.cloneRequest = function (req, token) {
        var headers = new HttpHeaders({
            'Content-type': 'application/json; charset=utf-8',
            Authorization: 'Bearer ' + token
        });
        var request = req.clone({ headers: headers });
        return request;
    };
    TokenInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TokenInterceptorService_Factory() { return new TokenInterceptorService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.AppService), i0.ɵɵinject(i5.AuthService), i0.ɵɵinject(i6.ToastrService)); }, token: TokenInterceptorService, providedIn: "root" });
    return TokenInterceptorService;
}());
export { TokenInterceptorService };
