import {Component, OnInit} from '@angular/core';
import {AuthService} from './modules/auth/shared/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'andrice-brain-app';


  constructor(private  router: Router, private authService: AuthService) {
  }

  ngOnInit(): void {
  }


}
