import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AuthComponent} from './auth.component';
import {AuthGuardService} from './shared/auth-guard.service';
import {AuthService} from './shared/auth.service';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RouterModule } from '@angular/router';
import { AdminModule } from '../admin/admin.module';
import { FirstLoginComponent } from './first-login/first-login.component';


@NgModule({
  declarations: [
    AuthComponent,
    ForgotPasswordComponent,
    FirstLoginComponent
  ],
  exports: [
    AuthComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    AdminModule
  ],
  providers: [
    AuthGuardService,
    AuthService
  ]
})
export class AuthModule { }
