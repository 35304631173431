/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../core/navbar/navbar.component.ngfactory";
import * as i3 from "../../core/navbar/navbar.component";
import * as i4 from "../../core/shared/navigation.service";
import * as i5 from "@angular/router";
import * as i6 from "./admin.component";
import * as i7 from "./shared/admin.service";
var styles_AdminComponent = [i0.styles];
var RenderType_AdminComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminComponent, data: {} });
export { RenderType_AdminComponent as RenderType_AdminComponent };
export function View_AdminComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵdid(1, 114688, null, 0, i3.NavbarComponent, [i4.NavigationService, i5.Router], { loaded: [0, "loaded"] }, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.adminService.resourcesLoaded; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); }, null); }
export function View_AdminComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-admin", [], null, null, null, View_AdminComponent_0, RenderType_AdminComponent)), i1.ɵdid(1, 245760, null, 0, i6.AdminComponent, [i7.AdminService, i5.Router, i4.NavigationService, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminComponentNgFactory = i1.ɵccf("app-admin", i6.AdminComponent, View_AdminComponent_Host_0, {}, {}, []);
export { AdminComponentNgFactory as AdminComponentNgFactory };
