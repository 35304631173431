import {Injectable} from '@angular/core';
import { NavTabModel} from './navTabModel';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private menuJsonPath = '/assets/menus/menu.json';
  private navRoutes: Array<NavTabModel> ;

  constructor(private http: HttpClient, private router: Router) {
    this.navRoutes = new Array<NavTabModel>();
  }

  // Get json menu from assets
  getAllMenu(): Observable<any> {
    return this.http.get(this.menuJsonPath);
  }

  // remove nav item of navbar from component name
  removeItemByComponent(componentName: string , menu: NavTabModel[]) {
    const found =  menu.find((item) => item.component === componentName);
    // if item in array delete him
    return  found ?  menu.splice(menu.indexOf(found), 1) : menu;
  }

  filterMenu(menu: [], event): NavTabModel[] {
    return menu.filter((section: NavTabModel[]) => {
      return  this.replaceIdsinRoutes(section, event).
      find((it) =>  it.routerLink === this.router.url &&
        it.icon !== 'arrow_back');
    }) [0];
  }

  // replace id in menu routes by good ids
  replaceIdsinRoutes(menu: NavTabModel[], event: ActivatedRoute) {
    menu.forEach((item) => {
      let itemRouterLink = item.routerLink;
      event.snapshot.paramMap.keys.forEach((key: string) => {
        itemRouterLink = itemRouterLink.replace(`:${key}`, event.snapshot.paramMap.get(key));
      });
      item.routerLink = itemRouterLink;
    });
    return menu;
  }

  // clear nav content
  clearAllItems() {
    this.navRoutes = [];
  }

  // set nav content
  setNavItems(navRoutes: Array<NavTabModel>) {
    this.navRoutes = navRoutes;
  }

  // get nav content
  getNavItems() {
    return this.navRoutes;
  }


}


