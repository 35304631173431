import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource, MatSort} from '@angular/material';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {AuthService} from '../../auth/shared/auth.service';
import {DialogService} from '../../../shared/dialog.service';
import {AdminService} from '../shared/admin.service';
import {User} from '../shared/user.model';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit, OnDestroy {
  dataSource = new MatTableDataSource<User>();

  // Subscriptions
  usersSubscription: Subscription;
  listUsers: Array<User>;

  user: User;

  displayedColumns: string[] = [
    '_userName',
    '_enable',
    '_accountLocked',
    '_role',
    '_trigram',
    'edit',
    'delete'
  ];

  constructor(
    private router: Router,
    private authService: AuthService,
    public adminService: AdminService,
    public dialogService: DialogService,
) {}

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit() {
    this.usersSubscription  = this.adminService.getUsers().subscribe(
      (users: User[]) => {
        this.listUsers = users.filter(user => this.authService.user.idEmployee !== user.idEmployee);
        this.dataSource.data = this.listUsers;
      });
    this.adminService.resourcesLoaded = true;
  }

  editUser(user: User) {
    this.router.navigate(['/users/edit', user.idEmployee]);
  }

  deleteUser(user: User): void {
    this.dialogService.openConfirmDialog(
      'Êtes-vous sûr de vouloir <strong>supprimer</strong> l\'utilisateur <strong>'
      + user.username + ' ?')
      .afterClosed().subscribe(result => {
      if (result) {
        this.adminService.deleteUserById(user.idEmployee).subscribe(() => {
          this.dataSource.data = this.dataSource.data.filter(i => i !== user);
          this.listUsers = this.dataSource.data;
        });
      }
    });
  }

  changeAccountEnable($event, user: User) {
    console.log(user);
    this.user = user;
    console.log($event.source.value);
  }

  changeAccountLocked($event) {
    alert('enable locked ' + $event);
  }

  ngOnDestroy() {
    this.usersSubscription.unsubscribe();
  }

}

