import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {NavigationService} from '../../core/shared/navigation.service';
import {Subscription} from 'rxjs';
import {NavTabModel} from '../../core/shared/navTabModel';
import {AdminService} from './shared/admin.service';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
  menu: NavTabModel[];
  routerSubscription: Subscription;
  idEmployee: number;

  constructor(public adminService: AdminService,
              private router: Router,
              public navigationService: NavigationService,
              private activatedRoute: ActivatedRoute) {
    this.getNavigationChanges();
  }

  ngOnInit() {
  }

  // Get navbar menu on route change
  getNavigationChanges() {
    // subscribe to router events on NavigationEnd and inject activatedRoute
    this.routerSubscription = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }))
      .subscribe( (event) => {
        this.adminService.resourcesLoaded = false;
        // Get id params in active route
        this.idEmployee = + event.snapshot.paramMap.get('idEmployee');
        // subscribe to json Menu and display the good one chosen by activated route url
        this.navigationService.getAllMenu().subscribe((menu) => {
          // filter menu and select the good one
          this.menu = this.navigationService.filterMenu(menu.admin , event);
          // set displayed menu
          this.navigationService.setNavItems(this.menu);
        });
      });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

}
