import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import 'rxjs-compat/add/observable/of';
import { User } from '../shared/user.model';
var MyErrorStateMatcher = /** @class */ (function () {
    function MyErrorStateMatcher() {
    }
    MyErrorStateMatcher.prototype.isErrorState = function (control, form) {
        var invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty &&
            control.parent.hasError('notSame') || control.parent.hasError('isDefaultPassword') || control.parent.hasError('isOldPassword'));
        return (invalidParent);
    };
    return MyErrorStateMatcher;
}());
export { MyErrorStateMatcher };
var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent() {
        this.showPassword = false;
        this.iconName = 'visibility';
        this.pwdMatcher = new MyErrorStateMatcher();
    }
    ResetPasswordComponent.prototype.ngOnInit = function () {
        /* if edit user or first login page
        The context in the methods checkPasswords and checkIfDefaultPassword is not
          referencing the ResetPasswordComponent, set the context using bind(this) */
        if (this.user !== undefined) {
            // if first login page, be sure that the new password is different to the old
            if (this.form.contains('oldPassword')) {
                // validators for first login page
                this.form.setValidators([this.checkPasswords.bind(this),
                    this.checkIfDefaultPassword.bind(this), this.checkIfOldPassword]);
            }
            else {
                // validators for edit user password
                this.form.setValidators([this.checkPasswords.bind(this), this.checkIfDefaultPassword.bind(this)]);
            }
        }
    };
    Object.defineProperty(ResetPasswordComponent.prototype, "password", {
        get: function () {
            return this.form.get('password').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ResetPasswordComponent.prototype, "confirmPassword", {
        get: function () {
            return this.form.get('confirmPassword').value;
        },
        enumerable: true,
        configurable: true
    });
    ResetPasswordComponent.prototype.changeVisibility = function () {
        this.showPassword = !this.showPassword;
        this.iconName = this.showPassword ? 'visibility_off' : 'visibility';
    };
    /* Called on each input in either password field */
    // validator function to verify if the passwords matches
    ResetPasswordComponent.prototype.checkPasswords = function (form) {
        // oldPassword is null inside EditUserComponent
        var oldPassword = form.contains('oldPassword') ? form.get('oldPassword').value : null;
        var pass = form.get('password').value;
        var confirmPass = form.get('confirmPassword').value;
        return pass !== confirmPass && pass !== this.user.trigram + '20andrice' &&
            pass !== oldPassword ? { notSame: true } : null;
    };
    // validator function to verify if the passwords matches and if it's not the password by default
    ResetPasswordComponent.prototype.checkIfDefaultPassword = function (form) {
        var pass = form.get('password').value;
        return pass === this.user.trigram + '20andrice' ? { isDefaultPassword: true } : null;
    };
    // validator function to verify if the new password is different to the old
    ResetPasswordComponent.prototype.checkIfOldPassword = function (form) {
        var oldPassword = form.get('oldPassword').value;
        var pass = form.get('password').value;
        return oldPassword === pass && pass !== '' ? { isOldPassword: true } : null;
    };
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
