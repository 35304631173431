import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppService } from '../../../shared/app.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AndriceOffer } from './andrice-offer.model';
import { Job } from './job.model';
import { Lot } from './lot.model';
import { WorkingDay } from './working-day.model';
import { AoContractModel } from './andrice-offer-contract.model';
import { DateOperationUtil } from '../../../shared/utils/date-utils';
import { first } from 'rxjs/operators';
import { findReadVarNames } from '@angular/compiler/src/output/output_ast';
import { environment } from '../../../../environments/environment';
import {Moment} from 'moment';
import {Reporting} from '../../auth/shared/reporting/reporting.model';
import {AuthService} from '../../auth/shared/auth.service';
import {ReportingService} from '../../auth/shared/reporting/reporting.service';

@Injectable({
  providedIn: 'root'
})
export class AndriceOfferService {
  private andriceOfferUrl = environment.baseUrlAndriceOffer + '/andriceoffers/';
  private jobUrl = environment.baseUrlAndriceOffer + '/jobs/';
  private lotUrl = environment.baseUrlAndriceOffer + '/lots/';
  private workingDaysUrl = environment.baseUrlAndriceOffer + '/workingdays/';

  resourcesLoaded = false;

  private andriceOffersSubject: Subject<AndriceOffer[]>;
  andriceOffersObservable: Observable<AndriceOffer[]>;

  private andriceOffersSelectedSubject: Subject<AndriceOffer>;
  andriceOffersSelected: Observable<AndriceOffer>;

  private idCreatedAndriceOfferSubject: Subject<number>;
  idCreatedAndriceOffer: Observable<number>;

  idCreatedObject: number;

  private workingDaysSubject: Subject<WorkingDay[]>;
  workingDays: Observable<WorkingDay[]>;

  private lotsSubject: Subject<Lot[]>;
  lots: Observable<Lot[]>;

  private jobsSubject: Subject<Job[]>;
  jobs: Observable<Job[]>;

  constructor(
    private router: Router,
    private appService: AppService,
    private toastr: ToastrService,
    private authService: AuthService,
    private reportingService: ReportingService
  ) {
    this.andriceOffersSubject = new Subject<AndriceOffer[]>();
    this.andriceOffersObservable = this.andriceOffersSubject.asObservable();

    this.andriceOffersSelectedSubject = new Subject<AndriceOffer>();
    this.andriceOffersSelected = this.andriceOffersSelectedSubject.asObservable();

    this.idCreatedAndriceOfferSubject = new Subject<number>();
    this.idCreatedAndriceOffer = this.idCreatedAndriceOfferSubject.asObservable();

    this.workingDaysSubject = new Subject<WorkingDay[]>();
    this.workingDays = this.workingDaysSubject.asObservable();

    this.lotsSubject = new Subject<Lot[]>();
    this.lots = this.lotsSubject.asObservable();

    this.jobsSubject = new Subject<Job[]>();
    this.jobs = this.jobsSubject.asObservable();
  }

  getIdFromLocation(location: any) {
    let str = location.toString();
    str = str.split('/');
    this.idCreatedObject = str[str.length - 1];
  }

  createAndriceOffer(andriceOffer: AndriceOffer): Observable<number> {
    return Observable.create(observer => {
      this.appService
        .postResource(this.andriceOfferUrl + 'create', andriceOffer)
        .subscribe(
          response => {
            console.log('POST request successful', response);
            this.getIdFromLocation(response.headers.getAll('location'));

            const report = new Reporting(new Date(), 'CREATION OA', this.authService.user.trigram, this.authService.user.idEmployee, 'AndriceOffer', andriceOffer.idClient);
            this.reportingService.createReport(report);

            observer.next(this.idCreatedObject);
            this.toastr.success('Offre Andrice créé avec succès !', '', {
              timeOut: 2000
            });
          },
          error => {
            this.toastr.error(
              'Erreur lors de la création de l\'offre !',
              '<mat-icon>info</mat-icon>',
              { timeOut: 2000 }
            );
            console.log('POST error : ', error);
          },
          () => {
            observer.complete();
          }
        );
    });
  }

  createJob(job: Job) {
    this.appService.postResource(this.jobUrl + 'create', job).subscribe(
      response => {
        console.log('POST request successful', response);
      },
      error => {
        this.toastr.error(
          'Erreur lors de la sauvegarde des travaux !',
          '',
          { timeOut: 2000 }
        );
        console.log('POST error : ', error);
      }
    );
  }
  createLot(lot: Lot) {
    return Observable.create(observer => {
      this.appService.postResource(this.lotUrl + 'create', lot).subscribe(
        response => {
          console.log('POST request successful', response);
          this.getIdFromLocation(response.headers.getAll('location'));
          observer.next(this.idCreatedObject);
        },
        error => {
          this.toastr.error(
            'Erreur lors de la sauvegarde des lots !',
            '',
            { timeOut: 2000 }
          );
          console.log('POST error : ', error);
        },
        () => {
          observer.complete();
        }
      );
    }).pipe(first());
  }

  createWorkingDay(workingDay: WorkingDay) {
    this.appService
      .postResource(this.workingDaysUrl + 'create', workingDay)
      .subscribe(
        response => {
          console.log('POST request successful', response);
          this.getIdFromLocation(response.headers.getAll('location'));
        },
        error => {
          this.toastr.error(
            'Erreur lors de la sauvegarde des jours ouvrés !',
            '',
            { timeOut: 2000 }
          );
          console.log('POST error : ', error);
        }
      );
  }

  getAndriceOffers() {
    this.appService.getResource(this.andriceOfferUrl).subscribe(
      response => {
        console.log(response);
        this.andriceOffersSubject.next(response);
      },
      error => {
        console.log('GET error : ' + error);
      }
    );
    return this.andriceOffersObservable.pipe(first());
  }

  getAndriceOffersByClientId(id: number): Observable<AndriceOffer[]> {
    this.appService
      .getResource(this.andriceOfferUrl + 'client/' + id)
      .subscribe(
        (response: AndriceOffer[]) => {
          console.log(response);
          this.andriceOffersSubject.next(response);
        },
        error => {
          console.log('GET error : ' + error);
        }
      );
    return this.andriceOffersObservable.pipe(first());
  }

  getAndriceOfferById(id: number): Observable<AndriceOffer> {
    return new Observable<AndriceOffer>((observer) => {
      this.appService.getResource(this.andriceOfferUrl + id).subscribe(
        (response: AndriceOffer) => {
          console.log('GET request successful', response);
          this.andriceOffersSelectedSubject.next(response);
          observer.next(response);
        },
        error => {
          this.toastr.error('Cet offre Andrice n\'existe plus !', '', {
            timeOut: 2000
          });
          console.log('GET error : ', error);
        },
        () => { observer.complete(); }
      );
    }).pipe(first());
  }

  getJobsByIdAo(id: number) {
    this.appService.getResource(this.jobUrl + 'andriceoffers/' + id).subscribe(
      (response: Job[]) => {
        console.log('GET request successful', response);
        this.jobsSubject.next(response);
      },
      error => {
        console.log('GET error : ', error);
      }
    );
    return this.jobs.pipe(first());
  }
  getLotByIdAo(id: number) {
    this.appService.getResource(this.lotUrl + 'andriceoffers/' + id).subscribe(
      (response: Lot[]) => {
        console.log('GET request successful', response);
        this.lotsSubject.next(response);
      },
      error => {
        console.log('GET error : ', error);
      }
    );
    return this.lots.pipe(first());
  }
  getWorkingDaysByIdLot(id: number): Observable<WorkingDay[]> {
    return new Observable<WorkingDay[]>((observer) => {
      this.appService.getResource(this.workingDaysUrl + 'lot/' + id).subscribe(
        (response: WorkingDay[]) => {
          console.log('GET request successful', response);
          this.workingDaysSubject.next(response);
          observer.next(response);
        },
        error => {
          console.log('GET error : ', error);
    },
        () => {observer.complete(); }
    );
  }).pipe(first());
}

  updateAndriceOffer(andriceOffer: AndriceOffer) {
    return new Observable(observer => {
      this.appService.putResource(this.andriceOfferUrl + andriceOffer.id, andriceOffer)
        .subscribe(
          response => {

            const report = new Reporting(new Date(), 'MISE A JOUR OA', this.authService.user.trigram, this.authService.user.idEmployee, 'AndriceOffer', andriceOffer.idClient);
            this.reportingService.createReport(report);

            console.log('PUT request successful', response);
            this.toastr.info('Offre Andrice mise à jour avec succès !', '', {
              timeOut: 2000
            });
            this.idCreatedAndriceOfferSubject.next(andriceOffer.id);
            observer.next(andriceOffer.id);
          },
          error => {
            console.log('GET error : ', error);
            this.toastr.error(
              'Erreur lors de la mise à jour de l\'offre Andrice !',
              '',
              { timeOut: 2000 }
            );
          },
          () => {
            observer.complete();
          }
        );
    }).pipe(first());
  }

  updateJob(job: Job) {
    this.appService.putResource(this.jobUrl + job.id, job).subscribe(
      response => {
        console.log('PUT request successful', response);
        this.idCreatedAndriceOfferSubject.next(job.idAo);
      },
      error => {
        console.log('GET error : ', error);
        this.toastr.error('Erreur lors de la mise à jour des travaux', '', {
          timeOut: 2000
        });
      }
    );
    return this.idCreatedAndriceOffer.pipe(first());
  }

  updateLot(lot: Lot) {
    this.appService.putResource(this.lotUrl + lot.id, lot).subscribe(
      response => {
        console.log('PUT request successful', response);
        this.idCreatedAndriceOfferSubject.next(lot.idAo);
      },
      error => {
        console.log('GET error : ', error);
        this.toastr.error('Erreur lors de la mise à jour des lots', '', {
          timeOut: 2000
        });
      }
    );
    return this.idCreatedAndriceOffer.pipe(first());
  }

  updateWorkingDay(workingday: WorkingDay) {
    this.appService
      .putResource(this.workingDaysUrl + workingday.id, workingday)
      .subscribe(
        response => {
          console.log('PUT request successful', response);
        },
        error => {
          console.log('GET error : ', error);
          this.toastr.error(
            'Erreur lors de la mise à jour des jours ouvrés',
            '',
            { timeOut: 2000 }
          );
        }
      );
  }

  deleteAndriceOfferById(id: number) {
    return this.appService.deleteResource(this.andriceOfferUrl + id).subscribe(
      response => {

        // const report = new Reporting(new Date(), 'SUPPRESSION OA', this.authService.user.trigram, this.authService.user.idEmployee, 'AndriceOffer', id);
        // this.reportingService.createReport(report);

        console.log('DELETE request successful ', response);
        this.andriceOffersSelectedSubject.next();
        this.toastr.info('L\'offre Andrice a été supprimée !', '', {
          timeOut: 2000
        });
      },
      error => {
        console.log('DELETE error : ', error);
        this.toastr.error(
          'Erreur lors de la suppression de l\'offre Andrice !',
          '',
          { timeOut: 2000 }
        );
      }
    );
  }
  deleteLotById(id: number) {
    return this.appService.deleteResource(this.lotUrl + id).subscribe(
      response => {
        console.log('DELETE request successful ', response);
        this.andriceOffersSelectedSubject.next();
      },
      error => {
        console.log('DELETE error : ', error);
        this.toastr.error('Erreur lors de la suppression du lot !', '', {
          timeOut: 2000
        });
      }
    );
  }
  deleteJobById(id: number) {
    return this.appService.deleteResource(this.jobUrl + id).subscribe(
      response => {
        console.log('DELETE request successful ', response);
        this.andriceOffersSelectedSubject.next();
      },
      error => {
        console.log('DELETE error : ', error);
        this.toastr.error('Erreur lors de la suppression des travaux !', '', {
          timeOut: 2000
        });
      }
    );
  }

  deleteWorkingDayById(id: number) {
    return this.appService.deleteResource(this.workingDaysUrl + id).subscribe(
      response => {
        console.log('DELETE request successful ', response);
        this.andriceOffersSelectedSubject.next();
      },
      error => {
        console.log('DELETE error : ', error);
        this.toastr.error('Erreur lors de la suppression du jour ouvré !', '', {
          timeOut: 2000
        });
      }
    );
  }

  createAndriceOfferLogicModel(andriceOfferContract: AoContractModel) {
    return Observable.create(observer => {
      this.appService
        .postResource(this.andriceOfferUrl + 'generateAO', andriceOfferContract)
        .subscribe(
          response => {
            console.log('POST request successful', response);
            console.log(andriceOfferContract);
            this.getIdFromLocation(response.headers.getAll('location'));
            observer.next(this.idCreatedObject);
          },
          error => {
            console.log('POST error : ', error);
          },
          () => {
            observer.complete();
          }
        );
    }).pipe(first());
  }

  public getPDF(path): Observable<Blob> {
    return this.appService.getResourcePdf(
      environment.baseUrlAndriceOffer + '/pdf/' + path
    );
  }

  // function to filter dates disable weekend and french holidays in the datepicker
  weekEndFilter = (d: Date): boolean => {
    const day = d.getDay();
    let holidays = [];
    const yearsList = [];
    const minYear = d.getFullYear() - 1;
    const maxYear = d.getFullYear() + 1;
    for (let y = minYear; y <= maxYear; y++) {
      yearsList.push(y);
    }
    yearsList.forEach(year => {
      holidays = holidays.concat(DateOperationUtil.JoursFeries(year));
    });
    const holidaysDates = [];
    holidays.forEach(hDate => {
      holidaysDates.push(hDate.toDateString());
    });
    return day !== 0 && day !== 6 && holidaysDates.indexOf(d.toDateString()) === -1;
  }

  public showPDF(path): void {
    console.log('path ', path);
    this.getPDF(path).subscribe(x => {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([x], { type: 'application/pdf' });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const fileURL = window.URL.createObjectURL(newBlob);
      window.open(fileURL, '_blank');
    });
  }

  getAndriceOfferMaxVersion(nbAo: number): Observable<AndriceOffer> {
    this.appService
      .getResource(this.andriceOfferUrl + 'getMaxVersion/' + nbAo)
      .subscribe(
        response => {
          console.log('GET request successful', response);
          this.andriceOffersSelectedSubject.next(response);
        },
        error => {
          console.log('GET error : ' + error);
        }
      );

    return this.andriceOffersSelected;
  }

  uploadAndriceOffer(data: any, fileName: string) {
    this.appService.uploadResource(environment.baseUrlAndriceOffer + '/upload',
      data, fileName).subscribe(response => {
        this.toastr.success('Offre andrice importée avec succès !', '', {
        timeOut: 2000
        });
    },
    error => {
      this.toastr.error('Erreur lors de l\'import de l\'offre andrice !', '', {
        timeOut: 2000
      });
    });
  }
}
