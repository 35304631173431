export class Reporting {
  constructor(
    public date: Date,
    public action: string,
    public trigramSalary: string,
    public idEmployee: number,
    public type: string,
    public idType: number
  ) {}

}
