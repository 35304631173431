import * as tslib_1 from "tslib";
import { NativeDateAdapter } from '@angular/material';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/core";
import * as i2 from "@angular/cdk/platform";
var FrenchDateAdapter = /** @class */ (function (_super) {
    tslib_1.__extends(FrenchDateAdapter, _super);
    function FrenchDateAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FrenchDateAdapter.prototype.getFirstDayOfWeek = function () {
        return 1;
    };
    FrenchDateAdapter.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FrenchDateAdapter_Factory() { return new FrenchDateAdapter(i0.ɵɵinject(i1.MAT_DATE_LOCALE, 8), i0.ɵɵinject(i2.Platform)); }, token: FrenchDateAdapter, providedIn: "root" });
    return FrenchDateAdapter;
}(NativeDateAdapter));
export { FrenchDateAdapter };
