<body class="bg-blue">
  <div class="stars">
      <div class="custom-navbar">
          <div class="brand-logo">
              <img  [routerLink]="['/home']"  src="/assets/img/logo_blanc.png" width="6%">
          </div>
      </div>
      <div class="central-body">
        <div class="text-container">
            <h2 class="main-text"><strong>404</strong></h2>
            <p class="p-text">Il semble que vous vous soyez perdu dans l'espace</p>
        </div>
          <a  [routerLink]="['/home']"  class="btn-go-home">ACCUEIL</a>
      </div>
      <div class="objects">
          <img class="object_rocket" src="/assets/img/404/fusee.svg" width="5%">
          <div class="earth-moon">
              <img class="object_earth" src="/assets/img/404/planete.svg" width="5%">
              <img class="object_moon" src="/assets/img/404/moon.svg" width="4%">
          </div>
          <div class="box_astronaut">
              <img class="object_astronaut" src="/assets/img/404/astronaut.svg" width="40%">
          </div>
      </div>
      <div class="glowing_stars">
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
      </div>

  </div>

</body>