<form [formGroup]="form">
    <div class="form-group row">
        <mat-form-field>
            <input matInput maxlength="20" [type]="showPassword ? 'text' : 'password'" placeholder="Nouveau mot de passe" formControlName="password" required [readonly]="user===undefined">
            <button mat-button matSuffix class="viewButton" (click)="changeVisibility()">
        <mat-icon>{{iconName}}</mat-icon>
      </button>
            <mat-error *ngIf="form.get('password').hasError('required')">Le mot de passe est <strong>requis</strong> !</mat-error>
            <mat-error *ngIf="form.get('password').hasError('minlength') && !form.get('password').hasError('required')">
                Le mot de passe introduit est trop court !
            </mat-error>
        </mat-form-field>
    </div>

    <div class="form-group row">
        <mat-form-field>
            <input matInput type="password" appDirPreventCopyCutPaste [errorStateMatcher]="pwdMatcher" [type]="showPassword ? 'text' : 'password'" placeholder="Confirmer le mot de passe" formControlName="confirmPassword" required [readonly]="user===undefined">
            <button mat-button matSuffix class="viewButton" (click)="changeVisibility()">
        <mat-icon>{{iconName}}</mat-icon>
      </button>
            <mat-error *ngIf="form.hasError('notSame')">
                Les mots de passe ne correspondent pas !</mat-error>
            <mat-error *ngIf="form.hasError('isDefaultPassword')">
                Saisissez un mot de passe différent de celui par défaut !</mat-error>
            <mat-error *ngIf="form.hasError('isOldPassword')">
                Saisissez un mot de passe différent de celui actuel !</mat-error>
        </mat-form-field>
    </div>
</form>