import {AdminService} from './../admin/shared/admin.service';
import {Component, OnInit} from '@angular/core';
import {AuthService} from './shared/auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  loginForm: FormGroup;
  showPassword = false;
  iconName = 'visibility';


  constructor(private authService: AuthService,
              private formBuilder: FormBuilder,
              private toastr: ToastrService,
              private adminService: AdminService,
              private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    // on initialise le FormGroup
    this.loginForm = this.formBuilder.group(
      {
        // on renseigne les champs du formulaire
        username: ['', Validators.required],
        password: ['', Validators.required]
      }
    );
  }

  onSubmitForm() {
    this.spinner.show();
    const timer = setTimeout(() => {
      /** spinner ends after 2 minutes */
      this.toastr.error('Nous ne parvenons pas à vous connecter. Veuillez réessayer.');
      this.spinner.hide();
    }, (60000));
    const formValue = this.loginForm.value;
    this.authService.login(formValue.username, formValue.password).subscribe(
      token => {
        console.log(token);
        this.authService.getAuthorities(token).subscribe( (data) => {
          this.authService.saveToken(token, data);
          this.spinner.hide();
          clearTimeout(timer);
          const mydata = {
            login: formValue.username,
            password: formValue.password
          };
          this.adminService.verifyPassword(mydata).subscribe();
        });
      },
      error => {
        this.spinner.hide();
        clearTimeout(timer);
        this.toastr.error('Erreur', 'Login ou mot de passe incorrect');
      }
    );
  }

  changeVisibility() {
    this.showPassword = !this.showPassword;
    this.iconName = this.showPassword ? 'visibility_off' : 'visibility';
  }

}
