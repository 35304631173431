import { Observable, Subject } from 'rxjs';
import { AppService } from '../../../shared/app.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../../shared/app.service";
import * as i3 from "ngx-toastr";
import * as i4 from "@angular/common/http";
var AdminService = /** @class */ (function () {
    function AdminService(router, appService, toastr, http) {
        this.router = router;
        this.appService = appService;
        this.toastr = toastr;
        this.http = http;
        this.getAllUsersUrl = environment.baseUrlOauth + '/user/all';
        this.createUserUrl = environment.baseUrlOauth + '/user/add';
        this.getUserByIdUrl = environment.baseUrlOauth + '/user/';
        this.routes = [];
        this.resourcesLoaded = false;
        this.selectedUserSubject = new Subject();
        this.usersSubject = new Subject();
        this.usersObservable = this.usersSubject.asObservable();
        this.selectedUser = this.selectedUserSubject.asObservable();
    }
    AdminService.prototype.getUsers = function () {
        var _this = this;
        var users = new Subject();
        this.appService.getResource(this.getAllUsersUrl).subscribe(function (response) {
            console.log('GET request successful', response);
            _this.usersSubject.next(response);
            users.next(response);
        }, function (error) {
            console.log('GET users error : ' + error);
        });
        return users.asObservable();
    };
    AdminService.prototype.trimValidator = function (control) {
        if (control.value.startsWith(' ')) {
            return {
                trimError: { value: 'control has leading whitespace' }
            };
        }
        if (control.value.endsWith(' ')) {
            return {
                trimError: { value: 'control has trailing whitespace' }
            };
        }
        return null;
    };
    AdminService.prototype.createUser = function (user) {
        var _this = this;
        return new Observable(function (observer) {
            _this.appService.postResource(_this.createUserUrl, user)
                .subscribe(function (response) {
                console.log('POST request successful', response);
                _this.toastr.success('Utilisateur créé avec succès !', '', { timeOut: 2000 });
                observer.next(response);
            }, function (error) {
                var errorMessage = 'Erreur lors de la création de l\'utilisateur ! ';
                var msg = error.error.message;
                if (error.status === 400) {
                    msg = msg.split('=').pop().split(',')[0];
                    errorMessage += '(' + msg + ')';
                }
                if (error.status === 401) {
                    msg = 'Utilisateur non authentifié';
                    errorMessage += '(' + msg + ')';
                }
                if (error.status === 500 || error.status === 504 || error.status === 503) {
                    msg = 'erreur côté serveur, veuillez contacter la team-IT';
                    errorMessage += '(' + msg + ')';
                }
                _this.toastr.error(errorMessage, 'Info:', { timeOut: 2000 });
            }, function () {
                observer.complete();
            });
        }).pipe(first());
    };
    AdminService.prototype.updateUser = function (user, id) {
        var _this = this;
        return new Observable(function (observer) {
            _this.appService.putResource(_this.getUserByIdUrl + id, user)
                .subscribe(function (response) {
                console.log('PUT request successful', response);
                _this.toastr.success('Utilisateur mis à jour avec succès !', '', { timeOut: 2000 });
                observer.next(response);
            }, function (error) {
                var errorMessage = 'Erreur lors de la mise à jour de l\'utilisateur ! ';
                var msg = error.error.message;
                if (error.status === 400) {
                    msg = msg.split('=').pop().split(',')[0];
                    errorMessage += '(' + msg + ')';
                }
                if (error.status === 401) {
                    msg = 'Utilisateur non authentifié';
                    errorMessage += '(' + msg + ')';
                }
                if (error.status === 500 || error.status === 504 || error.status === 503) {
                    msg = 'erreur côté serveur, veuillez contacter la team-IT';
                    errorMessage += '(' + msg + ')';
                }
                _this.toastr.error(errorMessage, 'Info:', { timeOut: 2000 });
            }, function () {
                observer.complete();
            });
        }).pipe(first());
    };
    AdminService.prototype.getUserById = function (id) {
        var _this = this;
        var user = new Subject();
        this.appService.getResource(this.getUserByIdUrl + id)
            .subscribe(function (response) {
            console.log('GET request successful', response);
            _this.selectedUserSubject.next(response);
            user.next(response);
        }, function (error) {
            _this.toastr.error('Cet Utilisateur n\'existe plus !', '', {
                timeOut: 2000
            });
            console.log('GET error : ', error);
        });
        return user.asObservable();
    };
    AdminService.prototype.deleteUserById = function (id) {
        var _this = this;
        var deleteSubject = new Subject();
        this.appService.deleteResource(this.getUserByIdUrl + id)
            .subscribe(function (response) {
            console.log('DELETE request successful ', response);
            deleteSubject.next(response);
            _this.toastr.info('Le compte utilisateur a été supprimé avec succès!', '', { timeOut: 2000 });
        }, function (error) {
            console.log('DELETE error : ', error);
            _this.toastr.error('Erreur lors de la suppression du salarié !', '', { timeOut: 2000 });
        });
        return deleteSubject.asObservable();
    };
    AdminService.prototype.resetPassword = function (data) {
        var _this = this;
        return new Observable(function (observer) {
            _this.appService
                .putResource(_this.getUserByIdUrl + 'preco', data)
                .subscribe(function (response) {
                _this.toastr.success('Mot de passe changé avec succès !', '', {
                    timeOut: 2000
                });
                observer.next(response);
            }, function (error) {
                var errorMessage = 'Erreur lors de la mise à jour ! ';
                var msg = error.message;
                if (error.status === 400) {
                    msg = msg
                        .split('=')
                        .pop()
                        .split(',')[0];
                    errorMessage += '(' + msg + ')';
                }
                if (error.status === 404) {
                    msg = 'Le mot de passe saisi est incorrect'
                        .split('=')
                        .pop()
                        .split(',')[0];
                    errorMessage += '(' + msg + ')';
                }
                if (error.status === 401) {
                    msg = 'Utilisateur non authentifié';
                    errorMessage += '(' + msg + ')';
                }
                if (error.status === 500 ||
                    error.status === 504 ||
                    error.status === 503) {
                    msg = 'erreur côté serveur, veuillez contacter la team-IT';
                    errorMessage += '(' + msg + ')';
                }
                _this.toastr.error(errorMessage, '', { timeOut: 2000 });
            }, function () {
                observer.complete();
            });
        }).pipe(first());
    };
    AdminService.prototype.verifyPassword = function (data) {
        var _this = this;
        return new Observable(function (observer) {
            _this.appService.postResource(_this.getUserByIdUrl + 'verifyPassword', data).subscribe(function (response) {
                if (response.body !== null) {
                    observer.next(response);
                    localStorage.setItem('login', data.login);
                    _this.router.navigate(['/preco']);
                }
                else if (response.body === null) {
                    observer.next(response);
                    _this.router.navigate(['/home']);
                }
            }, function (error) {
                _this.toastr.error('Cet Utilisateur n\'existe pas !', '', {
                    timeOut: 2000
                });
                console.log('GET error : ', error);
            });
        }).pipe(first());
    };
    AdminService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AppService), i0.ɵɵinject(i3.ToastrService), i0.ɵɵinject(i4.HttpClient)); }, token: AdminService, providedIn: "root" });
    return AdminService;
}());
export { AdminService };
