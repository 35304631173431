
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AppService} from 'src/app/shared/app.service';
import {DialogService} from '../../../shared/dialog.service';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private appService: AppService,
              private router: Router,
              public dialogService: DialogService,
              private auth: AuthService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean |
    import ('@angular/router').UrlTree | Observable<boolean |
    import ('@angular/router').UrlTree> | Promise<boolean |
    import ('@angular/router').UrlTree> {

    const urlwithoutParams = state.url.split('?')[0];
    if (this.auth.checkCredentials()) {
        // If authorities contains all the permissions that route permissions require , we check user credentials and can display page
        // If not, we open a dialog saying that we don't have rights
        //
      this.auth.getAuhtorities();
      let permissions = [];
      route.data.permissions ? permissions = route.data.permissions : permissions = [] ;
      if (this.auth.authorities.some(perm => permissions.includes(perm)) || permissions.length === 0) {
        if ( route.queryParamMap.has('sendRequest')) {
          this.auth.saveAuthInfo(route.queryParamMap.get('sendRequest'));
          this.router.navigate([urlwithoutParams]);
        }
        return this.auth.checkCredentials();
      } else {
          // role not authorised
          this.dialogService.openInfoDialog(
            'Vous n\'avez pas le droit d\'accéder à cette page !');
        }
      } else {
        if (route.queryParamMap.has('sendRequest')) {
          this.auth.saveAuthInfo(route.queryParamMap.get('sendRequest'));
          this.router.navigate([urlwithoutParams]);
        } else {
          this.router.navigate(['/auth']);
        }
    }

    }
}
