import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {AppService} from '../../../shared/app.service';
import {ToastrService} from 'ngx-toastr';
import {Employee} from './employee.model';
import {first} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {FormControl} from '@angular/forms';
import {Reporting} from '../../auth/shared/reporting/reporting.model';
import {AuthService} from '../../auth/shared/auth.service';
import {ReportingService} from '../../auth/shared/reporting/reporting.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  private employeeUrl = environment.baseUrlEmployees + '/employees/';
  resourcesLoaded = false;

  private employeesSubject = new Subject<Employee[]>();
  employeesObservable: Observable<Employee[]>;

  private selectedEmployeeSubject = new Subject<Employee>();
  selectedEmployee: Observable<Employee>;

  employees: Employee[];

  idCreatedObject: any;

  constructor(private router: Router,
              private appService: AppService,
              private toastr: ToastrService,
              private authService: AuthService,
              private reportingService: ReportingService) {
    this.employeesObservable = this.employeesSubject.asObservable();
    this.selectedEmployee = this.selectedEmployeeSubject.asObservable();
  }

  getIdFromLocation(location: any) {
    let str = location.toString();
    str = str.split('/');
    this.idCreatedObject = str[(str.length) - 1];
  }


  createEmployee(employee: Employee) {
    this.appService.postResource(this.employeeUrl + 'create', employee)
      .subscribe(
        (response) => {
          this.getIdFromLocation(response.headers.getAll('location'));
          const report = new Reporting(new Date(), 'EMBAUCHE', this.authService.user.trigram, this.authService.user.idEmployee, 'Employe', this.idCreatedObject);
          this.reportingService.createReport(report);

          console.log('POST request successful', response);

          this.getEmployeeById(this.idCreatedObject).subscribe();
          this.toastr.success('Candidat embauché avec succès ! ', '', {timeOut: 2000});

        },
        (error) => {
          let errorMessage = 'Erreur lors de la création du salarié ! ';
          let msg = error.error.message;
          if (error.status === 400) {
            msg = msg.split('=').pop().split(',')[0];
            errorMessage += '(' + msg + ')';
          }
          if (error.status === 401) {
            msg = 'utilisateur non authentifié';
            errorMessage += '(' + msg + ')';
          }
          if ( error.status === 500 || error.status === 504 || error.status === 503 ) {
            if(error.error.message.toString() === 'duplicated social Number') {
              errorMessage = 'Le numéro de sécurité sociale doit etre unique.' ;
            } else {
              msg = 'Une erreur côté serveur est survenue.Veuillez contacter la team-IT';
              errorMessage += '(' + msg + ')';
            }
          }
          this.toastr.error(errorMessage, 'Info:', {timeOut: 2000});
          console.log('POST error : ', error);
        }
      );
  }


  getEmployees(): Observable<Employee[]> {
    const employees = new Subject<Employee[]>();
    this.appService.getResource(this.employeeUrl)
      .subscribe(
        (response: Employee[]) => {
          console.log('GET request successful', response);
          this.employeesSubject.next(response);
          employees.next(response);
        },
        (error) => {
          console.log('GET error : ' + error);
        }
      );
    return employees.asObservable();
  }

  getEmployeeById(id: number): Observable<Employee> {
    return new Observable<Employee>( (observer) => {
      this.appService.getResource(this.employeeUrl + id)
        .subscribe(
          (response: Employee) => {
            console.log('GET request successful', response);
            this.selectedEmployeeSubject.next(response);
            observer.next(response);
          },
          error => {
            this.toastr.error('Ce salarié n\'existe plus !', '', {
              timeOut: 2000
            });
            console.log('GET error : ', error);
          },
          () => observer.complete()
        );
    }).pipe(first());

  }

  deleteEmployeeById(id: number) {
    return this.appService.deleteResource(this.employeeUrl + id)
      .subscribe(
        (response) => {

          const report = new Reporting(new Date(), 'SUPPRESSION EMPLOYE', this.authService.user.trigram, this.authService.user.idEmployee, 'Employe', id);
          this.reportingService.createReport(report);

          console.log('DELETE request successful ', response);
          this.toastr.info('Le salarié a été supprimé avec succès!', '', {timeOut: 2000});
        },
        (error) => {
          console.log('DELETE error : ', error);
          this.toastr.error('Erreur lors de la suppression du salarié !', '', {timeOut: 2000});
        }
      );
  }

  updateEmployee(id: number, employee: Employee): Observable<any> {
    return new Observable( (observer) =>
      this.appService.putResource(this.employeeUrl + id, employee)
        .subscribe(
          (response) => {

            const report = new Reporting(new Date(), 'MISE A JOUR EMPLOYE', this.authService.user.trigram, this.authService.user.idEmployee, 'Employe', employee.id);
            this.reportingService.createReport(report);

            console.log('PUT request successful', response);
            this.toastr.info('Salarié mis à jour avec succès !', '', {timeOut: 2000});
            observer.next(response);
            
          },
          (error) => {
            let errorMessage = 'Erreur lors de la mise à jour du salarié ! ';
            let msg = error.error.message;
            if (error.status === 400) {
              msg = msg.split('=').pop().split(',')[0];
              errorMessage += '(' + msg + ')';
            }
            if (error.status === 401) {
              msg = 'utilisateur non authentifié';
              errorMessage += '(' + msg + ')';
            }
            if ( error.status === 500 || error.status === 504 || error.status === 503 ) {
              if(error.error.message.toString() === 'duplicated social Number') {
                errorMessage = 'Le numéro de sécurité sociale doit etre unique.' ;
              } else {
                msg = 'Une erreur côté serveur est survenue.Veuillez contacter la team-IT';
                errorMessage += '(' + msg + ')';
              }
            }
            observer.next(error);
            this.toastr.error(errorMessage, 'Info:', {timeOut: 2000});
            console.log('UPDATE error : ', error);
          }, () => {
            observer.complete();
          }
        ));
  }

  trimValidator(control: FormControl) {
    if (control.value && control.value.startsWith(' ')) {
      return {
        trimError: { value: 'control has leading whitespace' }
      };
    }
    if (control.value && control.value.endsWith(' ')) {
      return {
        trimError: { value: 'control has trailing whitespace' }
      };
    }
    return null;
  }

  public getPDF(path): Observable<Blob> {
    return this.appService.getResourcePdf(
      environment.baseUrlTemplate + '/pdf/' + path
    );
  }

  public showPDF(path): void {
    this.getPDF(path).subscribe(x => {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([x], { type: 'application/pdf' });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const fileURL = window.URL.createObjectURL(newBlob);
      window.open(fileURL, '_blank');
    });
  }
}
