import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, FormGroupDirective, NgForm} from '@angular/forms';
import 'rxjs-compat/add/observable/of';
import {ErrorStateMatcher} from '@angular/material';
import { User } from '../shared/user.model';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidParent = !!(
      control && control.parent && control.parent.invalid && control.parent.dirty &&
      control.parent.hasError('notSame') || control.parent.hasError('isDefaultPassword') || control.parent.hasError('isOldPassword') );
    return (invalidParent);
  }
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {
  showPassword = false;
  iconName = 'visibility';
  pwdMatcher = new MyErrorStateMatcher();
  @Input() form: FormGroup;
  @Input() user: User;

  constructor() {}

  ngOnInit() {
    /* if edit user or first login page
    The context in the methods checkPasswords and checkIfDefaultPassword is not
      referencing the ResetPasswordComponent, set the context using bind(this) */
    if (this.user !== undefined) {
      // if first login page, be sure that the new password is different to the old
      if (this.form.contains('oldPassword')) {
        // validators for first login page
        this.form.setValidators([this.checkPasswords.bind(this),
          this.checkIfDefaultPassword.bind(this), this.checkIfOldPassword]);
      } else {
        // validators for edit user password
        this.form.setValidators([this.checkPasswords.bind(this), this.checkIfDefaultPassword.bind(this)]);
      }
    }
  }

  get password() {
    return this.form.get('password').value;
  }

  get confirmPassword() {
    return this.form.get('confirmPassword').value;
  }

  changeVisibility() {
    this.showPassword = !this.showPassword;
    this.iconName = this.showPassword ? 'visibility_off' : 'visibility';
  }

  /* Called on each input in either password field */
  // validator function to verify if the passwords matches
  checkPasswords(form: FormGroup): any {
    // oldPassword is null inside EditUserComponent
    const oldPassword = form.contains('oldPassword') ? form.get('oldPassword').value : null ;
    const pass = form.get('password').value;
    const confirmPass = form.get('confirmPassword').value;
    return pass !== confirmPass && pass !== this.user.trigram + '20andrice' &&
      pass !== oldPassword ? { notSame: true } : null;
  }

  // validator function to verify if the passwords matches and if it's not the password by default
  checkIfDefaultPassword(form: FormGroup): any {
    const pass = form.get('password').value;
    return pass === this.user.trigram + '20andrice' ? { isDefaultPassword: true } : null;
  }

  // validator function to verify if the new password is different to the old
  checkIfOldPassword(form: FormGroup): any {
    const oldPassword = form.get('oldPassword').value;
    const pass = form.get('password').value;
    return oldPassword === pass && pass !== '' ? { isOldPassword: true } : null;
  }

}
